import React from 'react';
import {NUMBERS_USER, optionsListCase, titlesValue1, titlesValue2} from "../../helpers/userapp";

export const OptionTitle = (params) => {
    if(params) {
        const arrayListValTitlesKey = [];
        optionsListCase?.map(function (optionItemCase) {
            if (optionItemCase.optionclick && optionItemCase.id === NUMBERS_USER.ITEMCASEONE) {
                arrayListValTitlesKey.push(titlesValue1);
            } else if (optionItemCase.optionclick && optionItemCase.id === NUMBERS_USER.ITEMCASETWO) {
                arrayListValTitlesKey.push(titlesValue2);
            }
        });

        return arrayListValTitlesKey[0];
    }
};
export default OptionTitle;