import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import Logo from '../../components/Layout/Logo';
import SocServiceLogo from '../../components/Layout/SocServiceLogo';
import VerticalSpace from '../../components/Layout/VerticalSpace';
import FlagArm from '../../components/Layout/images/flag-arm.png';
import moment from "moment";

const InnerWrapper = styled.div`
    font-family: 'Noto Sans Armenian';
    margin: 0 auto;
    max-width: 1550px;
    padding: 9px 24px;
`;

const CoatTitle = styled(Typography.Text)`
    display: grid;
    align-self: center;
    margin-left: 16px;
    font-size: 14px;
    color: #091024;
`;

const CoatCountry = styled(Typography.Text)`
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
`;

const CoatMinistery = styled(Typography.Text)`
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
`;

const CoatSite = styled(Typography.Text)`
    font-size: 14px;
`;

const PdfBody = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 50px;
`;

const Title = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 20px;
`;

const Context = styled.div`
    padding: 50px 0;
    font-size: 20px;
`;

const ApplicationInfoPrint = () => {
    const params = useParams();
    let constText = "";
    if(params?.appInfoCode.includes("Ֆ") === true) {
        constText = "Առաջնակի գնահատման դիմում՝";
    } else if(params?.appInfoCode.includes("Բ") === true) {
        constText = "Բողոքարկման դիմում՝";
    } else if(params?.appInfoCode.includes("ԿԳ") === true) {
        constText = "Կրկնակի գնահատման դիմում՝";
    }

    return (
        <>
            <InnerWrapper>
                <VerticalSpace size="large">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Space size="middle">
                                <Logo />
                                <img src={FlagArm} alt="Flag" style={{width: '7px'}}/>
                                <CoatTitle>
                                    <CoatCountry>Հայաստանի Հանրապետություն</CoatCountry>
                                    <CoatMinistery>Աշխատանքի եվ սոցիալական հարցերի նախարարություն</CoatMinistery>
                                    <CoatSite>www.mlsa.am</CoatSite>
                                </CoatTitle>
                            </Space>
                        </Col>
                        <Col>
                            <SocServiceLogo />
                        </Col>
                    </Row>
                    <PdfBody>
                        <Title>{`${constText} ${params?.appInfoCode}`}</Title>
                        <Context>
                            <Row>
                                <Col style={{marginBottom: '40px'}}>Հարգելի քաղաքացի՛,</Col>
                            </Row>
                            <Row>
                                <Col>Ձեր դիմումը հաջողությամբ ընդունվել է Միասնական սոցիալական ծառայության կողմից։</Col>
                            </Row>
                            <Row>
                                <Col style={{marginBottom: '40px'}}>Դիմումի ընթացքի մասին հավելյալ կծանուցվի։</Col>
                            </Row>
                            <Row>
                                <Col>Հարգանքով,</Col>
                            </Row>
                            <Row>
                                <Col>ՀՀ Աշխատանքի եւ սոցիալական հարցերի նախարարություն</Col>
                            </Row>
                            <Row>
                                <Col style={{marginTop: '40px'}}>{moment().format("DD/MM/YYYY ")}թ․</Col>
                            </Row>
                        </Context>
                    </PdfBody>

                </VerticalSpace>
            </InnerWrapper>
        </>
    );
};

export default ApplicationInfoPrint;
