import styled from 'styled-components';
import { Text } from './text';

const Fieldset = styled.fieldset`
  border: 2px solid #ed1b1b;
  border-radius: 10px;
  padding: 20px 15px;
  line-height: 1;
  color: #555965;

  legend {
    width: fit-content;
    border-bottom: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;

export const WarningSection = ({ title = 'Ուշադրություն', children }) => {
  return (
    <Fieldset>
      <Text as="legend" color="#ED1B1B">
        {title}
      </Text>
      <span>{children}</span>
    </Fieldset>
  );
};
