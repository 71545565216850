import styled, { css } from 'styled-components';
import { Input } from 'antd';

const defaultStyle = css`
  ${'' /* width: 70%; */}
  height: 45px;
  background: #ffffff;
  border: 1px solid #dddfe1;
  border-radius: 10px;

  :disabled {
    background-color: #ffffff;
    color: #7e7c7c;
  }
  :active {
    border: 1px solid #3c9d99;
  }
  :hover {
    border: 1px solid #3c9d99;
  }
  :focus {
    /* border: 1px solid #3c9d99;
    box-shadow: none; */
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 0.1875rem #bd13b8;
    outline: none;
  }
`;

const EdbInput = styled(Input)`
  &.ant-input {
    ${defaultStyle}
  }
`;

const TextArea = styled(Input.TextArea)`
  &.ant-input-textarea {
    .ant-input {
      border-radius: 10px;
      :active {
        border: 1px solid #3c9d99;
      }
      :hover {
        border: 1px solid #3c9d99;
      }
      :focus {
        border: 1px solid #3c9d99;
        box-shadow: none;
      }
    }
  }
`;

EdbInput.TextArea = TextArea;

const Password = styled(Input.Password)`
  &.ant-input-password {
    ${defaultStyle}
  }
`;

EdbInput.Password = Password;

export default EdbInput;
