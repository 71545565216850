import { useMutation } from '@tanstack/react-query';
import client from "../clientsubmitapplication";

const url = '/EDisability/CreateAppeal';
const useUserDataReassessment = (options = {}) => {
    const mutation = useMutation((values) => {
        return client.post(url, values);
    }, options);
    return mutation;
};


export default useUserDataReassessment;