import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Radio, Select, Form, Input } from 'antd';
import EdbRadio from '../../Radio/index';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  style,
  publicFormList,
  requiredMessage,
  formItemLayout,
  optionsListCase,
  ssnFormList,
  patternMessage,
  dataInfoReapetError,
  personStatusTrue,
  personDateApplicantFalse,
  notSubbmitingData,
  searchErrorFalse,
} from '../../../helpers/userapp';
import EdbSelect from '../../Select';
import VerticalSpace from '../../Layout/VerticalSpace';
import EdbInput from '../../Input';
import EdButton from '../../Button';
import EdbMaskedInput from '../../MaskedInput';
import useSsnVerificationPublic from '../../../api/User/useSsnVerificationPublic';
import EdbDatePicker from '../../DatePicker';
import moment from 'moment';
import GridModal from '../GridModal/GridModal';
import calculate_age from '../GeneralInfo/calculateAge';
import EdbCard from '../../Card';
const { Option } = Select;

export const ApplicantGrid = ({ selection, reassessmentField }) => {
  const form = Form.useFormInstance();
  const [valueSel, setValueSel] = useState();
  const [optionsListCase1, setoptionsListCase] = useState(optionsListCase);
  const [isSsnError, getSsnError] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const firstRadioButtonRef = useRef();

  const isSuccessLegalSearch = Form.useWatch('is_successApp');

  useEffect(() => {
    if (firstRadioButtonRef.current) {
      firstRadioButtonRef.current.focus();
    }
  }, []);

  const listClickHandlerCase = (ItemsSelectId) => {
    form.setFieldsValue({
      select_item1: null,
      select_item123: null,
      Representative_firstName: null,
      Representative_lastName: null,
      Representative_patronymicName: null,
      Representative_ssn: null,
      Representative_birthDate: null,
      Representative_phoneNumber: null,
      Representative_email: null,
    });

    const getSelect = optionsListCase1.map((itemCaseSelect) => {
      itemCaseSelect.optionclick = false;
      if (itemCaseSelect.id === ItemsSelectId) {
        itemCaseSelect.optionclick = true;
      }
      return itemCaseSelect;
    });

    setValueSel(getSelect);
  };

  const listClickRadio = (ItemSelect, event) => {
    form.setFieldsValue({
      select_item123: null,
    });
    const getSelectAll = optionsListCase1.map((itemCaseSelectAll) => {
      if (itemCaseSelectAll.optionclick) {
        itemCaseSelectAll.items_select = itemCaseSelectAll.items_select.map((itemCaseSelectAllChiild) => {
          itemCaseSelectAllChiild.optionclick = false;
          if (itemCaseSelectAllChiild.id === event) {
            itemCaseSelectAllChiild.optionclick = true;
          } else {
            itemCaseSelectAllChiild.optionclick = false;
          }
          return itemCaseSelectAllChiild;
        });
      }
      return itemCaseSelectAll;
    });
    setoptionsListCase(getSelectAll);
  };

  const { mutate: publicData } = useSsnVerificationPublic({
    onSuccess: (payload) => {
      if (payload.data === true) {
        form.setFieldsValue({
          is_successApp: true,
          is_success_trueApp: true,
        });
      } else {
        setOpenModalError(true);
        form.setFieldsValue({
          is_successApp: false,
          is_success_trueApp: false,
        });
      }
    },
    // onError: errorMessage,
  });

  const getParamsData = () => {
    // if(form.getFieldValue("Representative_ssn") !== isPersonalInfo) {
    let formData = form.getFieldsValue(true);
    form.setFieldsValue({
      Representative_phoneNumber: null,
      Representative_email: null,
    });

    form.resetFields(['Representative_phoneNumber']);

    if (form.getFieldValue('Representative_ssn') === form.getFieldValue('Person_ssn')) {
      getSsnError(dataInfoReapetError);
    } else {
      getSsnError(false);
      setOpenModalError(false);
      if (calculate_age(form.getFieldValue('Representative_birthDate')) < 16) {
        setOpenDateModal(true);
      } else {
        const jsonDataPublic = {
          ssn: form.getFieldValue('Representative_ssn'),
          firstName: form.getFieldValue('Representative_firstName'),
          lastName: form.getFieldValue('Representative_lastName'),
          patronymicName: form.getFieldValue('Representative_patronymicName'),
          birthDate: moment(form.getFieldValue('Representative_birthDate')).format('DD/MM/YYYY'),
          isLegal: true,
        };
        publicData(jsonDataPublic);
      }
    }
    // }
  };
  const resetParamsData = () => {
    form.resetFields([
      'Representative_phoneNumber',
      'is_successApp',
      'is_success_trueApp',
      'Representative_email',
      'Representative_firstName',
      'Representative_lastName',
      'Representative_patronymicName',
      'Representative_ssn',
      'Representative_birthDate',
    ]);
    getSsnError(false);
    setOpenModalError(false);
  };

  const hideDateModal = () => {
    setOpenDateModal(false);
    window.location.reload(false);
  };

  const hideDateModalError = () => {
    setOpenModalError(false);
    form.setFieldsValue({
      Representative_firstName: null,
      Representative_lastName: null,
      Representative_patronymicName: null,
      Representative_ssn: null,
      Representative_birthDate: null,
    });
  };

  const emailNoticeChangeApplicant = (e) => {
    if (e.target.value.length > 0) {
      form.setFieldsValue({
        email_check: 'true',
      });
    } else {
      if (form.getFieldValue('Representative_email') === null) {
        form.resetFields(['email_check']);
      } else {
        form.setFieldsValue({
          email_check: 'true',
        });
      }
    }
  };

  return (
    <>
      <div style={{ paddingTop: 50 }}>
        <Row
          style={{
            gap: 10,
            paddingBottom: 20,
          }}
        >
          <Col>
            <Form.Item
              {...formItemLayout}
              name="filling_application"
              rules={[{ required: true, message: requiredMessage }]}
              ref={firstRadioButtonRef}
              label={
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: '100%',
                    fontWeight: '700',
                    color: '#0B847F',
                    fontSize: '21px',
                    marginBottom: '10px',
                  }}
                >
                  Դիմումը լրացնում եմ
                </span>
              }
            >
              <Radio.Group className="radlo_button">
                {reassessmentField === 11 && selection === 3 ? (
                  <EdbRadio
                    key={optionsListCase1[1].id}
                    value={optionsListCase1[1].value}
                    name={optionsListCase1[1].id}
                    style={{ fontSize: '18px' }}
                    onClick={() => listClickHandlerCase(optionsListCase1[1].id)}
                    className="radlo_button"
                  >
                    {optionsListCase1[1].label}
                  </EdbRadio>
                ) : (
                  optionsListCase1.map((itemCase, index) => (
                    <div key={itemCase.id}>
                      <EdbRadio
                        key={itemCase.id}
                        value={itemCase.value}
                        name={itemCase.id}
                        style={{ fontSize: '18px' }}
                        onClick={() => listClickHandlerCase(itemCase.id)}
                        className="radlo_button"
                        ref={index === 0 ? firstRadioButtonRef : null}
                        aria-label={`Դիմումը լրացնում եմ՝ ${itemCase.label}`}
                      >
                        {itemCase.label}
                      </EdbRadio>

                      {itemCase.optionclick && itemCase.items_select.length > 0 && (
                        <>
                          <Form.Item
                            {...formItemLayout}
                            name="select_item1"
                            rules={[{ required: true, message: requiredMessage }]}
                          >
                            <EdbSelect
                              placeholder="Ընտրել"
                              onChange={(e) => listClickRadio(itemCase.items_select, e)}
                              style={{ width: '500px' }}
                            >
                              {itemCase.items_select.map((optionSelect) => (
                                <Option key={optionSelect.id} value={optionSelect.value}>
                                  {optionSelect.label}
                                </Option>
                              ))}
                            </EdbSelect>
                          </Form.Item>

                          {itemCase.items_select.map(
                            (itemCaseMap) =>
                              itemCaseMap.optionclick && (
                                <Form.Item
                                  {...formItemLayout}
                                  key={itemCaseMap.id}
                                  name="select_item123"
                                  rules={[{ required: true, message: requiredMessage }]}
                                >
                                  <Radio.Group className="radlo_button">
                                    {itemCaseMap.select_case.map((itemCaseMapSelect) => (
                                      <EdbRadio
                                        key={itemCaseMapSelect.id}
                                        value={itemCaseMapSelect.id}
                                        name={itemCaseMapSelect.id}
                                      >
                                        {itemCaseMapSelect.label}
                                      </EdbRadio>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            {optionsListCase1.map(
              (itemCase) =>
                itemCase.optionclick &&
                itemCase.form_case && (
                  <VerticalSpace key={1}>
                    <EdbCard
                      title="Օրինական ներկայացուցչի տվյալները"
                      style={{
                        marginBottom: 10,
                        borderTop: '1px solid #C0C1C2',
                        borderBottom: 0,
                        borderRight: 0,
                        borderLeft: 0,
                      }}
                      className="form__person_place"
                    >
                      <Row style={{ gap: 10 }}>
                        {publicFormList.data.map((publicSsnList, index) => (
                          <Col key={publicSsnList.label} span={7}>
                            <Form.Item
                              {...formItemLayout}
                              label={publicSsnList.label}
                              name={`Representative_${publicSsnList.name}`}
                              rules={[{ required: publicSsnList.required, message: requiredMessage }]}
                            >
                              {publicSsnList.name === 'birthDate' ? (
                                <EdbDatePicker
                                  format="DD/MM/YYYY"
                                  disabled={form.getFieldValue('is_successApp') === true ? 'disabled' : false}
                                  aria-label={publicSsnList.ariaLabel}
                                />
                              ) : (
                                <EdbInput
                                  disabled={form.getFieldValue('is_successApp') === true ? 'disabled' : false}
                                  style={style}
                                  aria-label={
                                    index === 0
                                      ? `Օրինական ներկայացուցչի տվյալները ${publicSsnList.ariaLabel}` // Concatenate the two strings with a space
                                      : publicSsnList.ariaLabel
                                  }
                                />
                              )}
                            </Form.Item>

                            <Form.Item label={false} name="is_successApp">
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                        ))}
                        <Col span={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {isSuccessLegalSearch ? (
                            <EdButton htmlType="primary" onClick={resetParamsData}>
                              Չեղարկել
                            </EdButton>
                          ) : (
                            <EdButton htmlType="primary" onClick={getParamsData} aria-label="Նույնականացնել">
                              Որոնել
                            </EdButton>
                          )}
                        </Col>
                        <Col span={15} /*style={{marginBottom: '30px'}}*/>
                          <Form.Item
                            label={false}
                            name="is_success_trueApp"
                            rules={[{ required: true, message: notSubbmitingData }]}
                          >
                            <Input type="hidden" />
                          </Form.Item>
                        </Col>
                      </Row>

                      {form.getFieldValue('is_successApp') === true ? (
                        personStatusTrue
                      ) : form.getFieldValue('is_successApp') === false ? (
                        <GridModal
                          title={
                            <h3>
                              <CheckCircleOutlined
                                tooltip="Ծանուցում"
                                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
                              />{' '}
                              Ծանուցում
                            </h3>
                          }
                          open={openModalError}
                          onOk={hideDateModalError}
                          onCancel={hideDateModalError}
                          footer={false}
                        >
                          {searchErrorFalse}
                        </GridModal>
                      ) : (
                        ''
                      )}

                      <Row style={{ margin: '0' }}>
                        <Col span={12}>{isSsnError}</Col>
                      </Row>

                      <Row style={{ gap: 10, borderTop: '1px solid #C0C1C2' }}>
                        {ssnFormList.data.map((ssnList) => (
                          <Col key={ssnList.label} span={7}>
                            <Form.Item
                              {...formItemLayout}
                              label={ssnList.label}
                              name={`Representative_${ssnList.name}`}
                              tooltip={
                                ssnList.name === 'email'
                                  ? 'Եթե ցանկանում եք ծանուցումները եւ/կամ որոշումը ստանալ էլ. փոստով, ապա այս դաշտի լրացումը պարտադիր է'
                                  : ''
                              }
                              rules={[
                                ssnList.name === 'email' ? { type: 'email', message: patternMessage } : '',
                                ssnList.name === 'phoneNumber'
                                  ? { pattern: /^[+]\d{2,}$/, message: patternMessage }
                                  : '',
                                { required: ssnList.required, message: requiredMessage },
                              ]}
                            >
                              {ssnList.name === 'phoneNumber' ? (
                                <EdbMaskedInput disabled={ssnList.disabled} size="large" style={style} />
                              ) : ssnList.name === 'email' ? (
                                <EdbInput
                                  disabled={ssnList.disabled}
                                  style={style}
                                  onChange={emailNoticeChangeApplicant}
                                />
                              ) : (
                                <EdbInput disabled={ssnList.disabled} style={style} />
                              )}
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </EdbCard>
                  </VerticalSpace>
                )
            )}
          </Col>
        </Row>
      </div>

      <>
        <GridModal
          title={
            <h3>
              <CheckCircleOutlined
                tooltip="Ծանուցում"
                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
              />{' '}
              Ծանուցում
            </h3>
          }
          open={openDateModal}
          onOk={hideDateModal}
          onCancel={hideDateModal}
          footer={false}
        >
          {personDateApplicantFalse}
        </GridModal>
      </>
    </>
  );
};

export default ApplicantGrid;
