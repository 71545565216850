import axios from 'axios';

const clientsubmit = axios.create({
  baseURL: process.env.REACT_APP_SUBMIT_APPLICATION_URL,
});

clientsubmit.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token')) || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
clientsubmit.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 500) {
      //window.location.href = `/${PATHS.ERROR_403}`;
    }
    return Promise.reject(error);
  }
);

export default clientsubmit;
