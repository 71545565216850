import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Radio, Row } from 'antd';
import EdbRadio from '../../Radio';
import { formItemLayout, NUMBERS_USER, approvedList, providedList, requiredMessage } from '../../../helpers/userapp';
import OptionTitle from '../OptionTitle';
import PersonAddress from '../PersonInfo/PersonAddress';
import VerticalSpace from '../../Layout/VerticalSpace';
import TableInfo from '../../Card/tableUser';
import EdbCheckbox from '../../Checkbox';
import PersonSsnReassessment from '../PersonInfo/PersonSsnReassessment';
import EdbInput from '../../Input';
import { DICTIONARY_URL, columnsTableInfo } from '../../../helpers/constants';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import EdbCard from '../../Card';

export const ReassessmentInfo = () => {
  const form = Form.useFormInstance();
  const [isEmailNull, setEmailNull] = useState(false);
  const [isPostIndexNull, setPostIndexNull] = useState(false);
  const [getDecision, setDecision] = useState(false);
  const { data: dataNotificationType } = useGetDictionaryPublic(DICTIONARY_URL.GET_NOTIFICATION_TYPES);

  const dataSource = [
    {
      decisionNumber: (
        <>
          <Form.Item style={{ margin: 0 }} name="decisionNumber" rules={[{ required: true, message: requiredMessage }]}>
            <EdbInput size="large" disabled />
          </Form.Item>
          <Form.Item style={{ margin: 0 }} noStyle name="decisionId" hidden>
            <EdbInput size="large" />
          </Form.Item>
        </>
      ),
      functionalityEvaluationDate: (
        <EdbInput size="large" disabled value={getDecision ? getDecision.evaluationDate : ''} />
      ),
      typeOfFunctionalEvaluation: (
        <EdbInput size="large" disabled value={getDecision ? getDecision.evaluationType : ''} />
      ),
      eligibleForAppeal: (
        <EdbInput size="large" disabled value={getDecision ? (getDecision.isAllowed === true ? 'Այո' : 'Ոչ') : ''} />
      ),
    },
  ];

  const arrayListValTitles = OptionTitle(true);

  const changeRadioNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  const changeReceiveNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  //! check usage of useEffect hook
  useEffect(() => {
    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    }
  });

  return (
    <>
      <VerticalSpace>
        <EdbCard
          title="Բողոքարկող անձի տվյալներ"
          style={{
            marginBottom: 10,
            borderTop: '1px solid #C0C1C2',
            borderBottom: '1px solid #C0C1C2',
            borderRight: 0,
            borderLeft: 0,
          }}
          className="form__person_place"
        >
          <PersonSsnReassessment
            title={'Բողոքարկող անձի տվյալներ'}
            checkEmail={isEmailNull}
            desicionInfo={setDecision}
          />
        </EdbCard>
      </VerticalSpace>

      <EdbCard
        title="Բողոքարկման համար անհրաժեշտ որոշման առկայություն"
        style={{
          marginBottom: 10,
          borderTop: 0,
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <TableInfo columns={columnsTableInfo} dataSource={dataSource} pagination={false} />
      </EdbCard>

      <PersonAddress checkPostIndex={isPostIndexNull} />

      <Row
        style={{
          gap: 10,
          borderBottom: '1px solid #C0C1C2',
        }}
      >
        <Col span={11}>
          <EdbCard
            title={arrayListValTitles[1]}
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
            tabIndex={0}
          >
            <Form.Item {...formItemLayout} name="radio_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group className="radlo_button" onChange={(e) => changeRadioNotice(e)}>
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.name}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
        <Col span={12}>
          <EdbCard
            title={arrayListValTitles[29]}
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
            tabIndex={0}
          >
            <Form.Item {...formItemLayout} name="receive_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group className="radlo_button" onChange={(e) => changeReceiveNotice(e)}>
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.name}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
      </Row>
      <EdbCard
        style={{
          marginBottom: '-15px',
          marginTop: 20,
          borderTop: 30,
          border: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item {...formItemLayout} name="provided_field" rules={[{ required: true, message: requiredMessage }]}>
          <EdbCheckbox.Group options={providedList} />
        </Form.Item>
      </EdbCard>
      <EdbCard
        style={{
          marginBottom: 0,
          marginTop: 0,
          borderTop: 30,
          border: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item {...formItemLayout} name="approved_field" rules={[{ required: true, message: requiredMessage }]}>
          <EdbCheckbox.Group options={approvedList} />
        </Form.Item>
      </EdbCard>
    </>
  );
};

export default ReassessmentInfo;
