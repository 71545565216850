import { notification } from 'antd';
import { clearLocalStorage } from '../hooks/useLocalStorage1';
import { APPLICATION_TAB_KEYS, DOCTOR_TYPE_NAME, PATHS } from './constants';


/** Logout the user */
export const logOut = () => {
  clearLocalStorage();
  window.location.reload();
};

export const noop = () => {};

export const errorMessage = (er) => {
  console.error(er);
  notification.error({
    message: 'Տեղի է ունեցել սխալ',
    description: er.response?.data?.Message,
  });
};

export const doctorTypes = Object.entries(DOCTOR_TYPE_NAME).map(([value, label]) => ({ value: +value, label }));
