import { useMutation } from '@tanstack/react-query';
import client from "../clientsubmitapplication";

const url = 'EDisability/CreateDeathApplication';
const useUserDataDeathApplication = (options = {}) => {
    const mutation = useMutation((values) => {
        return client.post(url, values);
    }, options);
    return mutation;
};


export default useUserDataDeathApplication;
