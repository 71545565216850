import { useMutation } from '@tanstack/react-query';
import client from "../clientsubmitapplication";

const useUserDataSend = (options = {}) => {
    const mutation = useMutation((values) => {
        return client.post(values[1].url, values[0]);
    }, options);
    return mutation;
};


export default useUserDataSend;