import React, {useState} from 'react';
import {Col, Row, Form, Input} from 'antd';
import EdButton from "../../Button";
import EdbInput from '../../../components/Input';
import {formItemLayout, style, publicDeathFormList} from '../../../helpers/userapp';
import EdbDatePicker from '../../DatePicker';
import usePersonDeath from '../../../api/User/usePersonDeath';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import { errorMessage } from '../../../helpers/utils';
import useCheckOtherApp from '../../../api/User/useCheckOtherApp';

export const DeathPerson = () => {
    const form = Form.useFormInstance();
    const [getSsnError, isSsnError] = useState(false);
    const [getSsnErrorFalse, isSsnErrorFalse] = useState(false);
    const [getSsnMessage, isSsnMessage] = useState(false);

    const { mutate: mutateCheckApplications } = useCheckOtherApp({
        onSuccess: (res) => {
            if (res.data === true) {
                form.setFieldsValue({
                    is_success: true,
                });
                isSsnMessage(true);
            }
        },
        onError: (err) => {
            errorMessage(err);
            form.setFieldsValue({
                is_success: '',
                PersonDeath_firstName: '',
                PersonDeath_lastName: '',
                PersonDeath_patronymicName: '',
                PersonDeath_ssn: '',
                PersonDeath_birthDate: '',
            });

            isSsnMessage(false);
        },
        enabled: false,
    });

    const { mutate: publicDataDeath } = usePersonDeath({
        onSuccess: (payload) => {
            if(payload.data.identified === true) {
                form.setFieldsValue({
                    PersonDeath_gender: payload?.data?.data?.gender ? payload?.data?.data?.gender : '',
                    PersonDeath_region: payload?.data?.data?.address?.region ? payload?.data?.data?.address?.region : '',
                    PersonDeath_community: payload?.data?.data?.address?.community ? payload?.data?.data?.address?.community : '',
                    PersonDeath_settlement: payload?.data?.data?.address?.settlement ? payload?.data?.data?.address?.settlement : '',
                    PersonDeath_street: payload?.data?.data?.address?.street ? payload?.data?.data?.address?.street : '',
                    PersonDeath_building: payload?.data?.data?.address?.building ? payload?.data?.data?.address?.building : '',
                    PersonDeath_buildingType: payload?.data?.data?.address?.buildingType ? payload?.data?.data?.address?.buildingType : '',
                    PersonDeath_apartment: payload?.data?.data?.address?.apartment ? payload?.data?.data?.address?.apartment : '',
                });

                isSsnError(
                    <p style={{fontSize: '17px', fontWeight: 'bold'}}>
                        <CheckOutlined style={{fontSize: '22px', color: 'green'}}/>
                        <span>{payload.data.title}</span>
                    </p>
                );

                mutateCheckApplications([{
                    ssn: form.getFieldValue('PersonDeath_ssn'),
                    subtypeId: form.getFieldValue('radio_button_id'),
                }, '/EDisability/CheckOtherApplicationsExistence']);
            } else {
                isSsnErrorFalse(
                    <p style={{fontSize: '17px', fontWeight: 'bold'}}>
                        <CloseOutlined style={{fontSize: '22px', color: 'red'}}/>
                        <span>{payload.data.title}</span>
                    </p>
                );

                form.resetFields(["is_success"]);
            }
        },
        onError: (err) => {
            errorMessage(err);
            form.resetFields(["is_success"]);
        },
    });

    const getParamsDataDeath = () => {
        const jsonDeathPerson =
            {
                "ssn": form.getFieldValue("PersonDeath_ssn"),
                "firstName": form.getFieldValue("PersonDeath_firstName"),
                "lastName": form.getFieldValue("PersonDeath_lastName"),
                "patronymicName": form.getFieldValue("PersonDeath_patronymicName"),
                "birthDate": moment(form.getFieldValue("PersonDeath_birthDate")).format('YYYY-MM-DD'),
            };
        publicDataDeath(jsonDeathPerson);
    };

    return (
        <Row style={{gap: 10,borderBottom: "1px solid #C0C1C2",}}>
            {publicDeathFormList.data.map((publicSsnList) =>
                <Col key={publicSsnList.label} span={7} xs={22} sm={11} md={7}
                     style={{display: publicSsnList.hidden, visibility: publicSsnList.hidden === 'none' ? 'hidden' : 'unset'}}>
                    <Form.Item
                        {...formItemLayout}
                        label={publicSsnList.label}
                        name={`PersonDeath_${publicSsnList.name}`}
                        rules={publicSsnList.rules}
                    >
                        {publicSsnList.name === 'birthDate' ? (
                            <EdbDatePicker format="DD/MM/YYYY" aria-label={publicSsnList.ariaLabel} disabled={form.getFieldValue("is_success") === true ? "disabled" : false}/>
                        ) : (
                            <EdbInput disabled={form.getFieldValue("is_success") === true ? "disabled" : false} aria-label={publicSsnList.ariaLabel} style={style}/>
                        )}
                    </Form.Item>
                </Col>
            )}
            <Col span={7} sm={24} xs={12} md={7} style={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '120px'}}>
                <EdButton htmlType="primary" onClick={getParamsDataDeath}>Որոնել</EdButton>
            </Col>
            <Col span={12}>{getSsnMessage ? getSsnError : getSsnErrorFalse}</Col>
            <Col span={12}>
                <Form.Item
                    label={false}
                    rules={[{required: 'required', message: "Մահացած անձի հարցման խափանում"}]}
                    name='is_success'
                >
                    <Input type="hidden"/>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default DeathPerson;
