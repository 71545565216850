import React, { useState } from 'react';
import { Form, Input, Select, DatePicker, Button } from 'antd';
import { formItemLayout, patternMessage, requiredMessage, uploadImgError } from '../../../helpers/userapp';
import { CloseOutlined, PaperClipOutlined, PlusSquareOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';

import EdbSelect from '../../Select';
import { DICTIONARY_URL } from '../../../helpers/constants';
import axios from 'axios';
import useGetFiles from '../../../api/Dictionary/useGetFiles';
import moment from 'moment';
import useFileDelete from '../../../api/User/useFileDelete';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
const { Option } = Select;

export const DeathDocs = ({ setAreFilesOk, subtypeId }) => {
  const form = Form.useFormInstance();
  const [reqFiles, setReqFiles] = useState([]);
  const [reqMessage, setReqMesaage] = useState([]);
  const [message, setMessage] = useState('Խնդրում ենք կցել բոլոր պարտադիր փաստաթղթերը');
  const [getFileUrl, setrFileUrl] = useState(false);
  const [getFileType, setFileType] = useState(false);
  const [getImgPreview, setImgPreview] = useState('');
  const [getImgCheckError, setImgCheckError] = useState(false);
  const [getAddButtonDisabled, setAddButtonDisabled] = useState(true);
  const [getFirstDate, setFirstDate] = useState(false);
  const { data: documentList } = useGetDictionaryPublic(
    `${DICTIONARY_URL.GET_ATTACHED_DOCS}?evaluationSubtype=${subtypeId}`,
    {
      enabled: !!form.getFieldValue('primary_assessment'),
      onSuccess: (data) => {
        const reqId = [];
        const reqNames = [];
        data.forEach((i) => {
          if (i.isMandatory) {
            reqId.push(i.id);
            reqNames.push(i.name);
          }
        });
        setReqFiles(reqId);
        setReqMesaage(reqNames);
      },
    }
    // { evaluationSubtype: form.getFieldValue('primary_assessment') }
  );

  useGetFiles(
    DICTIONARY_URL.GET_FILE_BY_NAME,
    {
      enabled: !!getFileUrl,
      onSuccess: (data) => {
        const objectURL = URL.createObjectURL(new Blob([data], { type: getFileType }));
        const projects = form.getFieldsValue('projects');

        const updatedProjects = projects.projects.map((project, key) => {
          if (key === getImgPreview) {
            return {
              ...project,
              file_item_show: objectURL,
              file_item_checksum: getFileUrl,
            };
          }

          return project;
        });
        const fileFields = updatedProjects.map((i) => {
          return i.doc_type_table;
        });
        setMessage('');
        let isOk = undefined;
        reqFiles.forEach((i) => {
          if (!fileFields.includes(i)) {
            setMessage(() => 'Խնդրում ենք կցել բոլոր պարտադիր փաստաթղթերը');
            isOk = false;
          }
        });
        setAreFilesOk(isOk === undefined ? true : false);

        projects.projects = updatedProjects;
        form.setFieldsValue({ ...projects });

        if (projects.projects.length === 0) {
          setAddButtonDisabled(true);
          setFirstDate(false);
        }
      },
    },
    {
      fileChecksum: getFileUrl,
      contentType: getFileType,
    }
  );

  const handleChange = (e, fieldKey) => {
    const projects = form.getFieldsValue('projects');
    if (projects.projects && projects.projects.length !== 0) {
      form.setFieldsValue({
        file_item_true: true,
      });
    } else {
      form.setFieldsValue({
        file_item_true: '',
      });
    }

    e.preventDefault();
    setImgCheckError(false);
    const fileSize = e.target.files[0].size;
    if (fileSize < 10000000) {
      const fileFieldsArr = form.getFieldsValue();
      const fileFields = fileFieldsArr.projects.pop();
      const url = `${process.env.REACT_APP_SUBMIT_APPLICATION_URL + '/EDisability/UploadFiles'}`;
      const img = e.target.files[0];
      const formDataImg = new FormData();

      formDataImg.append('Ssn', form.getFieldValue('Person_ssn') === undefined ? '' : form.getFieldValue('Person_ssn'));
      formDataImg.append(
        'DocumentNumber',
        fileFields.for_the_doc_table === undefined ? '' : fileFields.for_the_doc_table
      );
      formDataImg.append(
        'DocumentName',
        fileFields.doc_type_name_input === undefined ? '' : fileFields.doc_type_name_input
      );
      formDataImg.append(
        'DisabilityCauseId',
        fileFields.doc_type_name_select === undefined ? '' : fileFields.doc_type_name_select
      );
      formDataImg.append(
        'OfficeName',
        fileFields.name_of_the_provider_table === undefined ? '' : fileFields.name_of_the_provider_table
      );
      formDataImg.append(
        'BirthDate',
        form.getFieldValue('Person_birthDate') === undefined
          ? ''
          : moment(form.getFieldValue('Person_birthDate')).format('YYYY-MM-DD')
      );
      formDataImg.append('Hash', '');
      formDataImg.append(
        'ValidUntil',
        fileFields.issued_date ? moment(fileFields.issued_date).format('YYYY-MM-DD') : ''
      );
      formDataImg.append(
        'IssuedDate',
        fileFields.dalivery_date === undefined ? '' : moment(fileFields.dalivery_date).format('YYYY-MM-DD')
      );
      formDataImg.append('AttachedDocumentTypeId', fileFields.doc_type_table);
      formDataImg.append('File', img);

      axios
        .post(url, formDataImg)
        .then((res) => {
          if (res && res.data.isSuccess === true) {
            setrFileUrl(res.data.fileChecksum);

            setFileType(img.type);
            setImgPreview(fieldKey);
            setAddButtonDisabled(true);
            setFirstDate(false);
          }
        })
        .catch((error) => {
          if (error && (error.response.data.status === 400 || error.response.data.status === 500)) {
            setImgCheckError(uploadImgError);
          }
        });
    } else {
      setImgCheckError(uploadImgError);
    }
  };

  const onFileChange = (event) => {
    event.target.value = '';
  };

  const { mutate: mutateDeleteFile } = useFileDelete({
    onSuccess: (res) => {
      const projects = form.getFieldsValue('projects');
      if (projects.projects.length === 0) {
        form.setFieldsValue({
          file_item_true: '',
        });
      }
    },
    onError: (resError) => {
      console.log('error');
    },
    enabled: false,
  });

  const handleDelete = (e, fieldKey) => {
    e.stopPropagation();
    const projects = form.getFieldsValue('projects');
    function getFieldValue(arr) {
      for (let i = 0; i <= arr.length; i++) {
        if (i === fieldKey) return arr[i];
      }
    }
    const fileItemChecksum = getFieldValue(projects.projects);
    const updatedProjects = projects.projects.filter((project, key) => {
      return fileItemChecksum.file_item_checksum !== project.file_item_checksum;
    });
    projects.projects = updatedProjects;
    form.setFieldsValue({ ...projects });

    mutateDeleteFile({
      checksum: fileItemChecksum.file_item_checksum,
      ssn: form.getFieldValue('Person_ssn'),
      birthDate: form.getFieldValue('Person_birthDate'),
    });
  };

  const onChangeFirstDate = (e) => {
    var firstDate = e.format('YYYY-MM-DD');
    setFirstDate(firstDate);
  };

  return (
    <Form.List name="projects" key={1}>
      {(projects, { add, remove }) => {
        return (
          <div key={1}>
            {message && (
              <>
                <p style={{ fontSize: '16px', color: 'red' }}>{message}</p>
                <p style={{ fontSize: '12px', color: 'red' }}>*{reqMessage.join(',')}</p>
              </>
            )}
            <table style={{ width: '100%', textAlign: 'center' }} cellPadding={20}>
              <thead style={{ background: '#0B847F', color: '#FFF' }}>
                <tr>
                  <td style={{ borderRadius: '10px 0 0 0' }}>Փաստաթղթի տեսակ</td>
                  <td>Փաստաթղթի անվանում</td>
                  <td>Փաստաթղթի համար</td>
                  <td>Տրամադրող հաստատության անվանում</td>
                  <td>Տրամադրման ամսաթիվ</td>
                  <td>Վավեր է մինչև</td>
                  <td style={{ borderRadius: '0 10px 0 0' }}>
                    {getAddButtonDisabled && getAddButtonDisabled === true && (
                      <PlusSquareOutlined
                        style={{ fontSize: '20px' }}
                        onClick={() => {
                          add(setAddButtonDisabled(false));
                          setFirstDate(false);
                        }}
                      />
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {projects.map((field, index) => (
                  <tr key={field.key}>
                    <td>
                      <Form.Item {...formItemLayout} name={[field.name, 'doc_type_table']}>
                        <EdbSelect placeholder="Ընտրել" style={{ width: '300px' }}>
                          {documentList.map((docListItem) => (
                            <Option key={docListItem.id} value={docListItem.id}>
                              {docListItem.name}
                            </Option>
                          ))}
                        </EdbSelect>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        {...formItemLayout}
                        name={[field.name, 'doc_type_name_input']}
                        rules={[
                          { required: true, message: requiredMessage },
                          { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                        ]}
                      >
                        <Input
                          placeholder="Լրացնել"
                          size="large"
                          style={{ borderRadius: 10, color: '#000', width: '300px' }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        {...formItemLayout}
                        name={[field.name, 'for_the_doc_table']}
                        rules={[
                          { required: true, message: requiredMessage },
                          // { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                        ]}
                      >
                        <Input
                          placeholder="Լրացնել"
                          size="large"
                          style={{ borderRadius: 10, color: '#000', width: '200px' }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        {...formItemLayout}
                        name={[field.name, 'name_of_the_provider_table']}
                        rules={[
                          { required: true, message: requiredMessage },
                          { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                        ]}
                      >
                        <Input
                          placeholder="Լրացնել"
                          size="large"
                          style={{ borderRadius: 10, color: '#000', width: '200px' }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        {...formItemLayout}
                        name={[field.name, 'dalivery_date']}
                        rules={[{ required: true, message: requiredMessage }]}
                      >
                        <DatePicker
                          onChange={onChangeFirstDate}
                          disabledDate={(current) => current.isAfter(moment())}
                          style={{
                            borderRadius: 10,
                            color: '#000',
                            width: '300px',
                          }}
                          size="large"
                          placeholder=""
                        />
                      </Form.Item>
                    </td>

                    <td>
                      <Form.Item {...formItemLayout} name={[field.name, 'issued_date']}>
                        <DatePicker
                          disabledDate={(current) => {
                            if (getFirstDate !== false) {
                              return moment().add(-1, 'days') >= current;
                              // return current && current < moment(getFirstDate, 'YYYY-MM-DD');
                              // return current && current < moment();
                            } else {
                              return current;
                            }
                          }}
                          style={{
                            borderRadius: 10,
                            color: '#000',
                            width: '300px',
                          }}
                          size="large"
                          placeholder=""
                        />
                      </Form.Item>
                    </td>

                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Form.Item
                        {...formItemLayout}
                        name={[field.name, 'dalivery_date_hidden']}
                        style={{ visibility: 'hidden' }}
                      >
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues?.projects?.[field.name]?.file_item_show !==
                          currentValues?.projects?.[field.name]?.file_item_show
                        }
                      >
                        {({ getFieldValue }) => {
                          if (getFieldValue(['projects', field.name, 'file_item_show']) === undefined) {
                            return (
                              <Form.Item
                                key={field.key}
                                style={{ padding: '5px', marginBottom: 0 }}
                                name={[field.name, 'file_item_upload']}
                                rules={[{ required: true, message: requiredMessage }]}
                              >
                                <label htmlFor="filePicker" style={{ cursor: 'pointer' }}>
                                  <PaperClipOutlined style={{ color: '#000' }} />
                                </label>
                                <Input
                                  id="filePicker"
                                  key={1}
                                  style={{ visibility: 'hidden', width: 0, height: 0, padding: 0 }}
                                  type={'file'}
                                  onChange={(e) => handleChange(e, field.key)}
                                  onClick={onFileChange}
                                />
                              </Form.Item>
                            );
                          } else {
                            return <CheckOutlined style={{ color: 'green' }} />;
                          }
                        }}
                      </Form.Item>

                      <div key={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Item style={{ padding: '5px', marginBottom: 0 }} name={[field.name, 'file_item_show']}>
                          <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.projects?.[field.name]?.file_item_show !==
                            currentValues?.projects?.[field.name]?.file_item_show
                          }
                        >
                          {({ getFieldValue }) => {
                            return (
                              <a
                                href={getFieldValue(['projects', field.name, 'file_item_show'])}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <EyeOutlined style={{ color: '#000', marginRight: '7px' }} />
                              </a>
                            );
                          }}
                        </Form.Item>

                        <Form.Item name={[field.name, 'file_item_checksum']}>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.projects?.[field.name]?.file_item_show !==
                            currentValues?.projects?.[field.name]?.file_item_show
                          }
                        >
                          {({ getFieldValue }) => {
                            if (getFieldValue(['projects', field.name, 'file_item_show']) !== undefined) {
                              return (
                                <Button
                                  style={{ background: 'none', color: '#ffffff', border: 'none' }}
                                  onClick={(e) => handleDelete(e, field.name)}
                                >
                                  <CloseOutlined
                                    style={{ color: '#000', cursor: 'pointer' }}
                                    aria-label="չեղարկել փաստաթղթի վերաբերյալ լրացված տեղեկատվությունը"
                                  />
                                </Button>
                              );
                            } else {
                              return (
                                <Button
                                  style={{ background: 'none', color: '#ffffff', border: 'none' }}
                                  onClick={() => {
                                    remove(field.name);
                                    setAddButtonDisabled(true);
                                    setFirstDate(false);
                                  }}
                                >
                                  <CloseOutlined
                                    style={{ color: '#000', cursor: 'pointer' }}
                                    aria-label="չեղարկել փաստաթղթի վերաբերյալ լրացված տեղեկատվությունը"
                                  />
                                </Button>
                              );
                            }
                          }}
                        </Form.Item>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {getImgCheckError}
          </div>
        );
      }}
    </Form.List>
  );
};

export default DeathDocs;
