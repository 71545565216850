import styled from 'styled-components';
import { Table } from 'antd';

const TableInfo = styled(Table)`
    
    .ant-table-thead {
        tr {
       
            th {
                background: #0B847F;
                color: #FFF;
                
                &:first-child {
                    border-radius: 10px 0 0 0 !important;
                }
                
                &:last-child {
                    border-radius: 0 10px 0 0 !important;
                }
            }
        }
    }
`;

export default TableInfo ;