import React from 'react';
import './../../pages/HomePage/style.css';

const SkipToMain = () => {
  return (
    <a href="#main" className="skipTo-main">
      Անցնել հիմնական բովանդակությանը
    </a>
  );
};

export default SkipToMain;
