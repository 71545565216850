import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';

const defaultStyle = css`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #0b847f !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    box-shadow: 0 0 0 0.1875rem #bd13b8;
  }

  .ant-checkbox-checked:after {
    border-radius: 4px;
  }
  .ant-checkbox-checked {
    background: #0b847f;
    border-radius: 4px;

    .ant-checkbox-inner {
      background-color: #0b847f;
    }
  }

  .ant-checkbox-disabled + span {
    color: #091024;
    cursor: not-allowed;
    border-color: #0b847f;
  }
`;

const EdbCheckbox = styled(Checkbox)`
  ${defaultStyle}
`;

const Group = styled(Checkbox.Group)`
  ${defaultStyle}
`;

EdbCheckbox.Group = Group;

export default EdbCheckbox;
