import { useMutation } from '@tanstack/react-query';
import client from "../clientsubmitapplication";

const useCheckOtherApp = (options = {}) => {
    const mutation = useMutation((values) => {
        return client.post(values[1], values[0]);
    }, options);
    return mutation;
};


export default useCheckOtherApp;