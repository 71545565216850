import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { router } from './router';

import locale from 'antd/lib/locale/hy_AM';
import 'antd/dist/antd.min.css';
import './styles/globals.css';
import { ConfigProvider } from 'antd';
import { VALIDATE_MESSAGES } from './helpers/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Root = () => {
  return (
    <ConfigProvider
      locale={locale}
      form={{
        colon: false,
        validateMessages: VALIDATE_MESSAGES,
        autoComplete: 'off',
        requiredMark: <span style={{ color: 'red' }}>*</span>,
      }}
      theme={{
        token: {
          mainColor: '#0b847f',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default Root;
