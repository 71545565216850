import React, { useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import EdbInput from '../../../components/Input';
import {
  formItemLayout,
  patternMessage,
  evaluationList,
  requiredMessage,
  style,
  COLORS,
} from '../../../helpers/userapp';
import EdbSelect from '../../Select';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DICTIONARY_URL } from '../../../helpers/constants';
import Input from 'antd/es/input';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import EdbCard from '../../Card';
const { Option } = Select;

export const PersonAddress = (props) => {
  const form = Form.useFormInstance();
  const { data: regionList } = useGetDictionaryPublic(DICTIONARY_URL.GET_REGIONS);
  //! pay attention to var-s naming
  const [isRegionId, getRegionId] = useState();
  const [isCommunitiesId, getCommunitiesId] = useState();
  const [isSettlementId, getSettlementId] = useState();
  const [postIndexId, setPostIndexId] = useState();
  const [isCommunitiesData, getCommunitiesData] = useState([]);
  const [isSettlementData, getSettlementData] = useState([]);
  const [isPostOfficesData, getPostOfficesData] = useState([]);

  const actualRegionChange = (event) => {
    form.setFieldsValue({
      communityName: null,
      settlementName: null,
    });
    getRegionId(event);
    getSettlementData([]);
  };

  useGetDictionaryPublic(
    DICTIONARY_URL.GET_COMMUNITIES_BY_REGION_ID,
    {
      enabled: !!isRegionId,
      onSuccess: (data) => {
        getCommunitiesData(data);
      },
    },
    { regionId: isRegionId }
  );

  useGetDictionaryPublic(
    DICTIONARY_URL.GET_POST_OFFICES_BY_REGION_ID,
    {
      //! Use condition to perform call
      // enabled: true,
      enabled: !!(isRegionId || form.getFieldValue('regionName')),
      onSuccess: (data) => {
        getPostOfficesData(data);
      },
    },
    { regionId: isRegionId ? isRegionId : form.getFieldValue('regionName') }
  );

  const actualCommunityChange = (event) => {
    form.setFieldsValue({
      complete_evolution: null,
    });

    evaluationList.map((itemCaseSelectAll) => {
      itemCaseSelectAll.optionClick = false;
      return itemCaseSelectAll;
    });

    let communityId = '';
    //! pay attention to errors & code quality
    {
      isCommunitiesData &&
        isCommunitiesData.map((valueCommunity) => {
          if (valueCommunity.name === event) {
            communityId = valueCommunity.id;
            form.setFieldsValue({
              communityHiddenName: valueCommunity.id,
            });
          }
        });
    }

    form.setFieldsValue({
      settlementName: null,
    });

    getCommunitiesId(communityId);
    //! use optional chaining to avoid errors
    props?.checkCommunityId(communityId);
    getSettlementData([]);
  };

  //! pay attention to errors
  const actualSettlementChange = (event) => {
    isSettlementData &&
      isSettlementData.map((valueSettlement) => {
        if (valueSettlement.name === event) {
          form.setFieldsValue({
            settlementHiddenName: valueSettlement.id,
          });
        }
      });
    getSettlementId(event);
  };

  useGetDictionaryPublic(
    DICTIONARY_URL.GET_SETTLEMENT_BY_COMMUNITY_ID,
    {
      enabled: !!isCommunitiesId,
      onSuccess: (data) => {
        getSettlementData(data);
      },
    },
    { communityId: isCommunitiesId }
  );

  const postIndexChange = (value) => {
    setPostIndexId(value.toString())
    form.setFieldsValue({
      post_index_check: 'true',
    });
  };

  return (
    <EdbCard
      title="Փաստացի բնակության (գտնվելու) վայր"
      style={{
        marginBottom: 10,
        borderTop: '0',
        borderBottom: '1px solid #C0C1C2',
        borderRight: 0,
        borderLeft: 0,
      }}
      className="form__person_place"
    >
      <Row style={{ gap: 10 }}>
        <Col span={7} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Մարզ"
            name="regionName"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                isRegionId
                  ? 'ընտրված մարզ,' + regionList.find((region) => region.id === isRegionId)?.name
                  : 'Ընտրացանկից ընտրել մարզը որտեղ բնակվում եք'
              }
              onChange={actualRegionChange}
            >
              {regionList.map((valueRegion, index) => (
                <Option
                  key={valueRegion.id}
                  aria-posinset={index + 1} // Position of the option
                  aria-setsize={regionList.length} // Total number of options
                  value={valueRegion.id}
                >
                  {valueRegion.name}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Համայնք"
            name="communityName"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                isCommunitiesId
                  ? 'ընտրված Համայնք,' + isCommunitiesData.find((community) => community.id === isCommunitiesId)?.name
                  : 'Ընտրացանկից ընտրել համայնքը որտեղ բնակվում եք'
              }
              onChange={actualCommunityChange}
            >
              {isCommunitiesData &&
                isCommunitiesData.map((valueCommunity, index) => (
                  <Option
                    key={valueCommunity.name}
                    aria-posinset={index + 1} // Position of the option
                    aria-setsize={isCommunitiesData.length} // Total number of options
                    value={valueCommunity.name}
                  >
                    {valueCommunity.name}
                  </Option>
                ))}
            </EdbSelect>
          </Form.Item>

          <Form.Item {...formItemLayout} label={false} name="communityHiddenName" rules={[{ required: false }]}>
            <Input type="hidden" />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Բնակավայր"
            name="settlementName"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                isSettlementId
                  ? 'ընտրված Բնակավայր,' +
                    isSettlementData.find((valueSettlement) => valueSettlement.name === isSettlementId)?.name
                  : 'Ընտրացանկից ընտրել բնակավայրը որտեղ բնակվում եք'
              }
              onChange={actualSettlementChange}
              aria-haspopup="listbox"
              role="combobox"
            >
              {isSettlementData &&
                isSettlementData.map((valueSettlement, index) => (
                  <Option
                    key={valueSettlement.name}
                    aria-posinset={index + 1} // Position of the option
                    aria-setsize={isSettlementData.length} // Total number of options
                    value={valueSettlement.name}
                  >
                    {valueSettlement.name}
                  </Option>
                ))}
            </EdbSelect>
          </Form.Item>

          <Form.Item {...formItemLayout} label={false} name="settlementHiddenName" rules={[{ required: false }]}>
            <Input type="hidden" />
          </Form.Item>
        </Col>
        <Col span={7} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Փողոց"
            name="streetName"
            rules={[
              { required: true, message: requiredMessage },
              { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō\n-/ ]*$/, message: patternMessage },
            ]}
          >
            <EdbInput aria-label={'Մուտքագրեք փողոցը'} style={style} />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Կառույցի տեսակ"
            name="structureTypeName"
            rules={[{ pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō\n-/ ]*$/, message: patternMessage }]}
          >
            <EdbInput aria-label={'Մուտքագրեք կառույցի տեսակը'} style={style} />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Կառույցի համար"
            name="structureName"
            rules={[
              { required: true, message: requiredMessage },
              { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō\n-/ ]*$/, message: patternMessage },
            ]}
          >
            <EdbInput aria-label={'Մուտքագրեք կառույցի համարը'} style={style} />
          </Form.Item>
        </Col>
        <Col span={7} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Փոստային դասիչ"
            name="postIndexAddress"
            rules={[
              props.checkPostIndex === true
                ? { required: true, message: requiredMessage }
                : props.checkPostIndex === false
                ? { required: false, message: requiredMessage }
                : '',
            ]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                postIndexId
                  ? 'ընտրված փոստ,' + isPostOfficesData.find((post) => post.postalCode === postIndexId)?.postalCode
                  : 'Եթե ցանկանում եք ծանուցումները կամ որոշումը ստանալ փոստով ապա պարտադիր պետք է ընտրացանկից ընտրեք փոստային դասիչը'
              }
              onChange={postIndexChange}
            >
              {isPostOfficesData.map((valuePostOffices, index) => (
                <Option
                  key={valuePostOffices.postalCode}
                  aria-posinset={index + 1} // Position of the option
                  aria-setsize={isPostOfficesData.length} // Total number of options
                  value={valuePostOffices.postalCode}
                >
                  {valuePostOffices.postalCode}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
          <p style={{ marginTop: '15px', color: COLORS.whiteGray }}>
            <ExclamationCircleOutlined /> Եթե ցանկանում եք ծանուցումները եւ/կամ որոշումը ստանալ փոստով, ապա այս դաշտի
            լրացումը պարտադիր է։
          </p>
        </Col>
        <Col span={7} xs={24} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            label="Բնակարան"
            name="apartmentName"
            rules={[{ pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō\n-/ ]*$/, message: patternMessage }]}
          >
            <EdbInput aria-label={'Մուտքագրեք բնակարանի համարը'} style={style} />
          </Form.Item>
        </Col>
      </Row>
    </EdbCard>
  );
};

export default PersonAddress;
