import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Radio, Input, Select, Typography } from 'antd';
import EdbSelect from '../../Select';
import EdbRadio from '../../Radio';
import {
  checkTrue,
  formItemLayout,
  patternMessage,
  approvedList,
  providedList,
  requiredMessage,
} from '../../../helpers/userapp';
import OptionTitle from '../OptionTitle';
import EdbCheckbox from '../../Checkbox';
import EdbCard from '../../Card';

const { Option } = Select;

export const AssessmentQuestionnaireLast = () => {
  const form = Form.useFormInstance();
  const [valueBuild, setValueBuild] = useState();
  const [valueEducation, setValueEducation] = useState();
  const [valueWork, setValueWork] = useState();
  const [valueWorkPlace, setValueWorkPlace] = useState();
  const [getStudyValue, setStudyValue] = useState();
  const [getWorkTypeValue, setWorkTypeValue] = useState();

  const [ownAppartment, setOwnAppartment] = useState();
  const [educationalSupport, setEducationalSupport] = useState();
  const [workEnviroment, setWorkEnviroment] = useState();
  const [workPlace, setWorkPlace] = useState();
  const style = { borderRadius: 10 };
  const firstRadioButtonRef = useRef();

  useEffect(() => {
    if (firstRadioButtonRef.current) {
      firstRadioButtonRef.current.focus();
    }
  }, []);

  const onChangeBuild = (e) => {
    setOwnAppartment(e);
    form.setFieldsValue({
      own_appartment_input: null,
    });

    if (e === 1) {
      setValueBuild(
        <Col xs={22} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            name="own_appartment_input"
            rules={[
              { required: true, message: requiredMessage },
              { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
            ]}
          >
            <Input placeholder="ինչպիսի" size="large" style={style} />
          </Form.Item>
        </Col>
      );
    } else {
      setValueBuild();
    }
  };

  const onChangeEducation = (e) => {
    setEducationalSupport(e);
    form.setFieldsValue({
      support_educational_institution_input: null,
    });

    if (e === 1) {
      setValueEducation(
        <>
          <Col xs={22} sm={11} md={7}>
            <Form.Item
              {...formItemLayout}
              name="support_educational_institution_input"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="ինչպիսի" size="large" style={style} />
            </Form.Item>
          </Col>
        </>
      );
    } else {
      setValueEducation();
    }
  };

  const onChangeWork = (e) => {
    setWorkEnviroment(e);
    form.setFieldsValue({
      adjust_the_work_environment_input: null,
    });

    if (e === 1) {
      setValueWork(
        <>
          <Col xs={22} sm={11} md={7}>
            <Form.Item
              {...formItemLayout}
              name="adjust_the_work_environment_input"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="ինչպիսի" size="large" style={style} />
            </Form.Item>
          </Col>
        </>
      );
    } else {
      setValueWork();
    }
  };

  const onChangeWorkPlace = (e) => {
    setWorkPlace(e);
    form.setFieldsValue({
      obstacles_in_the_workplace_input: null,
    });

    if (e === 1) {
      setValueWorkPlace(
        <>
          <Col xs={22} sm={11} md={7}>
            <Form.Item
              {...formItemLayout}
              name="obstacles_in_the_workplace_input"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="ինչպիսի" size="large" style={style} />
            </Form.Item>
          </Col>
        </>
      );
    } else {
      setValueWorkPlace();
    }
  };

  const changeStudy = (event) => {
    setValueEducation();
    form.setFieldsValue({
      attend_educational_institution: null,
      adapt_learning_materials: null,
      support_educational_institution_input: null,
      support_educational_institution: null,
    });

    if (event.target.value === 1) {
      setStudyValue(true);
    } else {
      setStudyValue(false);
    }
  };

  const changeWorkType = (event) => {
    setValueWork();
    setValueWorkPlace();
    form.setFieldsValue({
      adjust_the_work_environment: null,
      adjust_the_work_environment_input: null,
      obstacles_in_the_workplace: null,
      requalification_courses: null,
    });

    if (event.target.value === 1) {
      setWorkTypeValue(true);
    } else {
      setWorkTypeValue(false);
    }
  };

  const arrayListValTitles = OptionTitle(true);

  return (
    <>
      <EdbCard
        title={arrayListValTitles[16]}
        style={{
          marginBottom: 10,
          borderTop: '1px solid #C0C1C2',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="use_hearing_aid_label" style={{ display: 'none' }} tabIndex={0}>
          {arrayListValTitles[16]}
        </h3>
        <Form.Item {...formItemLayout} name="use_hearing_aid" rules={[{ required: true, message: requiredMessage }]}>
          <Radio.Group className="radlo_button_assessment" aria-labelledby="use_hearing_aid_label" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                value={itemChoose.value}
                name={itemChoose.name}
                className="radlo_button"
                ref={firstRadioButtonRef}
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[17]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="use_wheelchair" style={{ display: 'none' }}>
          {arrayListValTitles[17]}
        </h3>
        <Form.Item
          {...formItemLayout}
          name="use_wheelchair"
          tabIndex={0}
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group className="radlo_button_assessment" aria-labelledby="use_wheelchair" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                tabIndex={0}
                value={itemChoose.value}
                name={itemChoose.name}
                className="radlo_button"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[18]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={11} md={7}>
          <Form.Item {...formItemLayout} name="own_appartment" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                ownAppartment
                  ? 'Ունե՞ք բնակարանը, շենքը հարմարեցնելու անհրաժեշտություն, ընտրված տարբերակ,' +
                    checkTrue.data.find((item) => item.value === ownAppartment)?.label
                  : 'Ընտրացանկից ընտրեք Ունե՞ք բնակարանը, շենքը հարմարեցնելու անհրաժեշտություն'
              }
              onChange={(e) => onChangeBuild(e)}
            >
              {checkTrue.data.map((checkAppartment) => (
                <Option key={checkAppartment.value} value={checkAppartment.value}>
                  {checkAppartment.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Row style={{ marginTop: '20px' }}>
          <Col span={8}>{valueBuild}</Col>
        </Row>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[19]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="attendant_support" style={{ display: 'none' }} tabIndex={0}>
          {arrayListValTitles[19]}
        </h3>
        <Form.Item {...formItemLayout} name="attendant_support" rules={[{ required: true, message: requiredMessage }]}>
          <Radio.Group className="radlo_button_assessment" aria-labelledby="attendant_support" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                tabIndex="0"
                value={itemChoose.value}
                name={itemChoose.name}
                className="radlo_button"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={
          <Typography.Text style={{ whiteSpace: 'normal', color: '#0b847f' }}>{arrayListValTitles[20]}</Typography.Text>
        }
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="attitude_of_people" style={{ display: 'none' }}>
          {arrayListValTitles[20]}
        </h3>
        <Form.Item
          {...formItemLayout}
          name="attitude_of_people"
          tabIndex={0}
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group className="radlo_button_assessment" aria-labelledby="attitude_of_people" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                tabIndex="0"
                value={itemChoose.value}
                name={itemChoose.name}
                className="radlo_button"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[21]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="study_any_educational_institution" style={{ display: 'none' }}>
          {arrayListValTitles[21]}
        </h3>
        <Form.Item
          {...formItemLayout}
          tabIndex={0}
          name="study_any_educational_institution"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group
            className="radlo_button_assessment"
            aria-labelledby="study_any_educational_institution"
            role="radiogroup"
          >
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                value={itemChoose.value}
                name={itemChoose.name}
                onChange={(e) => changeStudy(e)}
                className="radlo_button"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      {getStudyValue && getStudyValue === true && (
        <>
          <EdbCard
            title={arrayListValTitles[22]}
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <h3 id="attend_educational_institution" style={{ display: 'none' }}>
              {arrayListValTitles[22]}
            </h3>
            <Form.Item
              {...formItemLayout}
              tabIndex={0}
              name="attend_educational_institution"
              rules={[{ required: true, message: requiredMessage }]}
            >
              <Radio.Group
                className="radlo_button_assessment"
                aria-labelledby="attend_educational_institution"
                role="radiogroup"
              >
                {checkTrue.data.map((itemChoose) => (
                  <EdbRadio
                    key={itemChoose.id}
                    value={itemChoose.value}
                    name={itemChoose.name}
                    className="radlo_button"
                    tabIndex="0"
                  >
                    {itemChoose.label}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>

          <EdbCard
            title={
              <Typography.Text style={{ whiteSpace: 'normal', color: '#0b847f' }}>
                {arrayListValTitles[23]}
              </Typography.Text>
            }
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <h3 id="adapt_learning_materials" style={{ display: 'none' }}>
              {arrayListValTitles[23]}
            </h3>
            <Form.Item
              {...formItemLayout}
              tabIndex={0}
              name="adapt_learning_materials"
              rules={[{ required: true, message: requiredMessage }]}
            >
              <Radio.Group
                className="radlo_button_assessment"
                aria-labelledby="adapt_learning_materials"
                role="radiogroup"
              >
                {checkTrue.data.map((itemChoose) => (
                  <EdbRadio
                    key={itemChoose.id}
                    value={itemChoose.value}
                    name={itemChoose.name}
                    className="radlo_button"
                    tabIndex="0"
                  >
                    {itemChoose.label}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>

          <EdbCard
            title={
              <Typography.Text
                style={{
                  whiteSpace: 'normal',
                  color: '#0b847f',
                }}
              >
                {'Կա՞ աջակցության անհրաժեշտություն կրթական հաստատություն հաճախելու կամ դասերը պատրաստելու հարցում'}
              </Typography.Text>
            }
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <Col xs={22} sm={11} md={7}>
              <Form.Item
                {...formItemLayout}
                name="support_educational_institution"
                rules={[{ required: true, message: requiredMessage }]}
              >
                <EdbSelect
                  placeholder="Ընտրել"
                  aria-label={
                    educationalSupport
                      ? 'Կա՞ աջակցության անհրաժեշտություն կրթական հաստատություն հաճախելու կամ դասերը պատրաստելու հարցում, ընտրված տարբերակ,' +
                        checkTrue.data.find((item) => item.value === educationalSupport)?.label
                      : 'Ընտրացանկից ընտրեք, Կա՞ աջակցության անհրաժեշտություն կրթական հաստատություն հաճախելու կամ դասերը պատրաստելու հարցում'
                  }
                  onChange={(e) => onChangeEducation(e)}
                >
                  {checkTrue.data.map((checkAppartment) => (
                    <Option key={checkAppartment.value} value={checkAppartment.value}>
                      {checkAppartment.label}
                    </Option>
                  ))}
                </EdbSelect>
              </Form.Item>
            </Col>

            <Row style={{ marginTop: '20px' }}>{valueEducation}</Row>
          </EdbCard>
        </>
      )}

      <EdbCard
        title={arrayListValTitles[24]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="work_type" style={{ display: 'none' }}>
          {arrayListValTitles[24]}
        </h3>
        <Form.Item
          {...formItemLayout}
          name="work_type"
          tabIndex={0}
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group className="radlo_button_assessment" aria-labelledby="work_type" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                value={itemChoose.value}
                name={itemChoose.name}
                onChange={(e) => changeWorkType(e)}
                className="radlo_button"
                tabIndex="0"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      {getWorkTypeValue && getWorkTypeValue === true && (
        <>
          <EdbCard
            title={arrayListValTitles[25]}
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <Col span={7} xs={22} sm={11} md={7}>
              <Form.Item
                {...formItemLayout}
                name="adjust_the_work_environment"
                rules={[{ required: true, message: requiredMessage }]}
              >
                <EdbSelect
                  placeholder="Ընտրել"
                  aria-label={
                    workEnviroment
                      ? 'Ունե՞ք աշխատանքային միջավայրի հարմարեցման անհրաժեշտություն, ընտրված տարբերակ,' +
                        checkTrue.data.find((item) => item.value === workEnviroment)?.label
                      : 'Ընտրացանկից ընտրեք, Ունե՞ք աշխատանքային միջավայրի հարմարեցման անհրաժեշտություն'
                  }
                  onChange={(e) => onChangeWork(e)}
                  style={{ width: '500px' }}
                >
                  {checkTrue.data.map((checkAppartment) => (
                    <Option key={checkAppartment.value} value={checkAppartment.value}>
                      {checkAppartment.label}
                    </Option>
                  ))}
                </EdbSelect>
              </Form.Item>
            </Col>

            <Row style={{ marginTop: '20px' }}>{valueWork}</Row>
          </EdbCard>

          <EdbCard
            title={arrayListValTitles[26]}
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <Col span={7} xs={22} sm={11} md={7}>
              <Form.Item
                {...formItemLayout}
                name="obstacles_in_the_workplace"
                rules={[{ required: true, message: requiredMessage }]}
              >
                <EdbSelect
                  placeholder="Ընտրել"
                  aria-label={
                    workPlace
                      ? 'Ունե՞ք խոչընդոտներ աշխատելու համար, ընտրված տարբերակ,' +
                        checkTrue.data.find((item) => item.value === workPlace)?.label
                      : 'Ընտրացանկից ընտրեք, Ունե՞ք խոչընդոտներ աշխատելու համար'
                  }
                  onChange={(e) => onChangeWorkPlace(e)}
                  style={{ width: '500px' }}
                >
                  {checkTrue.data.map((checkAppartment) => (
                    <Option key={checkAppartment.value} value={checkAppartment.value}>
                      {checkAppartment.label}
                    </Option>
                  ))}
                </EdbSelect>
              </Form.Item>
            </Col>

            <Row style={{ marginTop: '20px' }}>{valueWorkPlace}</Row>
          </EdbCard>

          <EdbCard
            title={arrayListValTitles[27]}
            style={{
              marginBottom: 10,
              borderTop: '0',
              borderBottom: '1px solid #C0C1C2',
              borderRight: 0,
              borderLeft: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <h3 id="requalification_courses" style={{ display: 'none' }}>
              {arrayListValTitles[27]}
            </h3>
            <Form.Item
              {...formItemLayout}
              name="requalification_courses"
              tabIndex={0}
              rules={[{ required: true, message: requiredMessage }]}
            >
              <Radio.Group
                className="radlo_button_assessment"
                aria-labelledby="requalification_courses"
                role="radiogroup"
              >
                {checkTrue.data.map((itemChoose) => (
                  <EdbRadio
                    key={itemChoose.id}
                    value={itemChoose.value}
                    name={itemChoose.name}
                    className="radlo_button"
                    tabIndex="0"
                  >
                    {itemChoose.label}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </>
      )}

      <EdbCard
        title={arrayListValTitles[28]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <h3 id="financially_independent" style={{ display: 'none' }}>
          {arrayListValTitles[28]}
        </h3>
        <Form.Item
          {...formItemLayout}
          tabIndex={0}
          name="financially_independent"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group className="radlo_button_assessment" aria-labelledby="financially_independent" role="radiogroup">
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio
                key={itemChoose.id}
                tabIndex="0"
                value={itemChoose.value}
                name={itemChoose.name}
                className="radlo_button"
              >
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        style={{
          marginBottom: '-15px',
          marginTop: 20,
          borderTop: 30,
          border: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item {...formItemLayout} name="provided_field" rules={[{ required: true, message: requiredMessage }]}>
          <EdbCheckbox.Group options={providedList} />
        </Form.Item>
      </EdbCard>
      <EdbCard
        style={{
          marginBottom: 0,
          marginTop: 0,
          borderTop: 30,
          border: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item {...formItemLayout} name="approved_field" rules={[{ required: true, message: requiredMessage }]}>
          <EdbCheckbox.Group options={approvedList} />
        </Form.Item>
      </EdbCard>
    </>
  );
};

export default AssessmentQuestionnaireLast;
