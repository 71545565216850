import { useQuery } from '@tanstack/react-query';

import client from '../clientsubmitapplication';

const useGetDictionaryPublic = (url, options = { enabled: true }, params = {}) => {
  const result = useQuery([url, params], () => client.get(url, { params }), {
    ...options,
    select: (data) => data.data,
  });
  const { data, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? data : [],
  };
};

export default useGetDictionaryPublic;
