import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps, Spin, Form, Button } from 'antd';
import EdButton from '../Button';
import EdbButton from '../../components/Button';
import GridCard from '../Card/grid';
import {
  caregiverCase,
  selfCareDifficulties,
  houseHoldItem,
  movementDifficulties,
  bodyPosition,
  communicationDifficulties,
  useTransportList,
  smokeItemList,
  evaluationList,
  optionsListCase,
  familyComposition,
  familyStatus,
  livingItems,
  NUMBERS_USER,
  helpVideo,
  GENDER_LIST_USER,
} from '../../helpers/userapp';
import { CheckCircleOutlined } from '@ant-design/icons';

import FirstGrid from './FirstGrid';
import ApplicantGrid from './ApplicantGrid';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import GeneralInfoLast from './GeneralInfoLast/GeneralInfoLast';
import AssessmentQuestionnaire from './AssessmentQuestionnaire/AssessmentQuestionnaire';
import AssessmentQuestionnaireLast from './AssessmentQuestionnaireLast/AssessmentQuestionnaireLast';
import GridModal from './GridModal/GridModal';
import useUserDataSend from '../../api/User/useUserDataSend';
import { APP_SUBTYPES, DICTIONARY_URL, PATHS } from '../../helpers/constants';
import ReassessmentInfo from './ReassessmentInfo/ReassessmentInfo';
import useUserDataReassessment from '../../api/User/useUserDataReassessment';
import moment from 'moment';
import useGetDictionaryPublic from '../../api/Dictionary/useGetDictonaryPublic';
import HomeLogo from '../Layout/images/home.svg';
import GeneralInfoDeath from './GeneralInfoDeath';
import DeathPersonDocuments from './GeneralInfoDeath/DeathPersonDocuments';
import useUserDataDeathApplication from '../../api/User/useUserDataDeathApplication';
import { APPLICATION_URLS } from '../../api/appUrls';
import CausativeChange from './CausativeChange';
import useCreateAppeal, { CREATE_CAUSATIVE_APPEAL, CREATE_PSPA_APPEAL } from '../../api/Application/useCreateAppeal';

const GridStep = ({ isLoading = false }) => {
  //! -> Harmful code, should be at least refactored or rewritten

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: healthsList } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_HEALTHS);
  const { data: dataNotificationType } = useGetDictionaryPublic(DICTIONARY_URL.GET_NOTIFICATION_TYPES);
  const { data: educationItemsPublic } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_EDUCATIONS);
  const { data: occupationsItemsPublic } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_OCCUPATIONS);
  const { data: listItems } = useGetDictionaryPublic(DICTIONARY_URL.GET_SUBTYPES);

  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selection, setSelection] = useState();
  const [reassessmentField, setReassessmentField] = useState();
  const [areFilesOk, setAreFilesOk] = useState(false);
  const [subtypeId, setSubtypeId] = useState(null);
  //! state names should be more intuative
  const [getDisableItem, setDisableItem] = useState(true);
  const [getButtonSubmit, setButtonSubmit] = useState(true);
  const [getPrintButton, setPrintButton] = useState(false);
  const [getConfirmText, setConfirmText] = useState(false);
  const [getConfirmApplicationNumber, setConfirmApplicationNumber] = useState();
  const buttonRef = useRef(null);
  const buttonRefsec = useRef(null);

  const { data: getRegionList } = useGetDictionaryPublic(DICTIONARY_URL.GET_REGIONS);

  //! missing setState function, if it's constant use const & remove useState, https://react.dev/reference/react/useState
  // let steps = useState([
  //   {
  //     id: 0,
  //     title: 'Դիմում',
  //     content: (
  //       <FirstGrid
  //         countItems={selection}
  //         setCountItems={setSelection}
  //         currentItemDisable={setDisableItem}
  //         setSubtypeId={setSubtypeId}
  //       />
  //     ),
  //     optionClick: false,
  //   },
  //   {
  //     id: 1,
  //     title: 'Դիմող',
  //     content: <ApplicantGrid />,
  //     optionClick: false,
  //   },
  //   {
  //     id: 2,
  //     title: 'Ընդհանուր տվյալներ',
  //     content: <GeneralInfo subTypeId={subTypeId} />,
  //     optionClick: false,
  //   },
  //   {
  //     id: 3,
  //     title: 'Ընդհանուր տվյալներ',
  //     content: <GeneralInfoLast />,
  //     optionClick: false,
  //   },
  //   {
  //     id: 4,
  //     title: 'Ինքնագնահատման հարցաշար',
  //     content: <AssessmentQuestionnaire />,
  //     optionClick: false,
  //   },
  //   {
  //     id: 5,
  //     title: 'Ինքնագնահատման հարցաշար',
  //     content: <AssessmentQuestionnaireLast />,
  //     optionClick: false,
  //   },
  // ]);
  let steps = [
    {
      id: 0,
      title: 'Դիմում',
      content: (
        <FirstGrid
          countItems={selection}
          setCountItems={setSelection}
          currentItemDisable={setDisableItem}
          setSubtypeId={setSubtypeId}
          setReassessment={setReassessmentField}
        />
      ),
      optionClick: false,
    },
    {
      id: 1,
      title: 'Դիմող',
      content: <ApplicantGrid />,
      optionClick: false,
    },
    {
      id: 2,
      title: 'Ընդհանուր տվյալներ',
      content: <GeneralInfo subtypeId={subtypeId} />,
      optionClick: false,
    },
    {
      id: 3,
      title: 'Ընդհանուր տվյալներ',
      content: <GeneralInfoLast />,
      optionClick: false,
    },
    {
      id: 4,
      title: 'Ինքնագնահատման հարցաշար',
      content: <AssessmentQuestionnaire />,
      optionClick: false,
    },
    {
      id: 5,
      title: 'Ինքնագնահատման հարցաշար',
      content: <AssessmentQuestionnaireLast />,
      optionClick: false,
    },
  ];

  if (selection === 3) {
    steps = [
      {
        id: 0,
        title: 'Դիմում',
        content: (
          <FirstGrid
            setReassessment={setReassessmentField}
            countItems={selection}
            setCountItems={setSelection}
            currentItemDisable={setDisableItem}
            setSubtypeId={setSubtypeId}
          />
        ),
        optionClick: false,
      },
      {
        id: 1,
        title: 'Դիմող',
        content: <ApplicantGrid reassessmentField={reassessmentField} selection={selection} />,
        optionClick: false,
      },
      {
        id: 2,
        title: 'Ընդհանուր տվյալներ',
        content:
          //* 9 - causative change, 8 - PSPA appeal
          !!(subtypeId === 9 || subtypeId === 8 || subtypeId === 11) ? (
            <CausativeChange subtypeId={subtypeId} />
          ) : (
            <ReassessmentInfo />
          ),
        optionClick: false,
      },
    ];
  }

  if (selection === 4) {
    steps = [
      {
        id: 0,
        title: 'Դիմում',
        content: (
          <FirstGrid
            countItems={selection}
            setCountItems={setSelection}
            currentItemDisable={setDisableItem}
            setSubtypeId={setSubtypeId}
            setReassessment={setReassessmentField}
          />
        ),
        optionClick: false,
      },
      {
        id: 1,
        title: 'Դիմող',
        content: <ApplicantGrid />,
        optionClick: false,
      },
      {
        id: 2,
        title: 'Ընդհանուր տվյալներ',
        content: <GeneralInfoDeath />,
        optionClick: false,
      },
      {
        id: 3,
        title: 'Ընդհանուր տվյալներ',
        content: <DeathPersonDocuments setAreFilesOk={setAreFilesOk} subtypeId={subtypeId} />,
        optionClick: false,
      },
    ];
  }

  const next = () => {
    form.validateFields().then(() => {
      window.scrollTo(0, 0);
      setCurrent(current + 1);

      steps.reduce((stepsItem, stepsValue) => {
        if (current === stepsValue.id) {
          stepsValue.optionClick = true;
        }
        steps[current + 1].optionClick = true;
        return stepsItem;
      }, []);
    });
  };

  const { mutate: mutateInfo, isLoading: infoSubmitLoading } = useUserDataSend({
    onSuccess: (res) => {
      setOpenModal(true);
      setConfirmText(res.data.applicationCreateMessage);
      setConfirmApplicationNumber(res.data.applicationNumber);
    },
    onError: (resError) => {
      setConfirmText(resError.response.data.Message);
      setOpenModal(true);
      setPrintButton(false);
      setConfirmApplicationNumber('');
    },
    enabled: false,
  });

  const { mutate: reassessmentDeathPerson } = useUserDataDeathApplication({
    onSuccess: (res) => {
      setOpenModal(true);
      setConfirmText(res.data.applicationCreateMessage);
      setConfirmApplicationNumber(res.data.applicationNumber);
    },
    onError: (resError) => {
      setConfirmText(resError.response.data.Message);
      setOpenModal(true);
      setPrintButton(false);
      setConfirmApplicationNumber('');
    },
    enabled: false,
  });

  const { mutate: createAppeal } = useCreateAppeal(CREATE_CAUSATIVE_APPEAL, {
    onSuccess: (res) => {
      setOpenModal(true);
      setConfirmText(res.data.applicationCreateMessage);
      setConfirmApplicationNumber(res.data.applicationNumber);
      form.resetFields();
    },
    onError: (resError) => {
      setConfirmText(resError.response.data.Message);
      setOpenModal(true);
      setPrintButton(false);
      setConfirmApplicationNumber('');
    },
  });

  const { mutate: createPSPAAppeal } = useCreateAppeal(CREATE_PSPA_APPEAL, {
    onSuccess: (res) => {
      setOpenModal(true);
      setConfirmText(res.data.applicationCreateMessage);
      setConfirmApplicationNumber(res.data.applicationNumber);
      form.resetFields();
    },
    onError: (resError) => {
      setConfirmText(resError.response.data.Message);
      setOpenModal(true);
      setPrintButton(false);
      setConfirmApplicationNumber('');
    },
  });

  const { mutate: reassessment } = useUserDataReassessment({
    onSuccess: (res) => {
      setOpenModal(true);
      setConfirmText(res.data.applicationCreateMessage);
      setConfirmApplicationNumber(res.data.applicationNumber);
    },
    onError: (resError) => {
      setConfirmText(resError.response.data.Message);
      setOpenModal(true);
      setPrintButton(false);
      setConfirmApplicationNumber('');
    },
    enabled: false,
  });

  const finishProcess = () => {
    form.validateFields().then(() => {
      let formData = form.getFieldsValue(true);
      //! Recall Array.prototype.reduce() method and it's usage
      //! https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
      const getCurrentRegionName = getRegionList.reduce((regionListItem, regionListValue) => {
        if (regionListValue.id === formData.regionName) {
          regionListItem = regionListValue.name;
        }
        return regionListItem;
      }, []);

      const idRadioButton = listItems.reduce((idRadioButtonItem, currentValue, index) => {
        if (currentValue.id === formData.primary_assessment) {
          const radioItem = currentValue.items.reduce((currentValueItemsVal, currentItemsValue) => {
            if (currentItemsValue.name === formData.radio_button_name) {
              currentValueItemsVal = currentItemsValue.id;
            }
            return currentValueItemsVal;
          }, 0);
          return radioItem;
        }
        return idRadioButtonItem;
      }, 0);

      const evaluationTypeId = listItems.reduce((evaluationTypeItem, currentValue) => {
        if (currentValue.id === formData.primary_assessment) {
          evaluationTypeItem = currentValue.id;
        }
        return evaluationTypeItem;
      }, []);

      let legalRepresentativeItem;
      if (formData.filling_application === 1) {
        legalRepresentativeItem = null;
      } else {
        legalRepresentativeItem = {
          firstName: formData.Representative_firstName,
          lastName: formData.Representative_lastName,
          patronymicName: formData.Representative_patronymicName ? formData.Representative_patronymicName : null,
          documentNumber: null,
          phoneNumber: formData.Representative_phoneNumber,
          email: formData.Representative_email ? formData.Representative_email : null,
          ssn: formData.Representative_ssn,
          documentType: null,
          birthDate: moment(formData.Representative_birthDate).format('YYYY-MM-DD'),
          gender: null,
        };
      }

      const valueRadioNotice = dataNotificationType.reduce((noticeRadioTypeItem, noticeRadioValue) => {
        if (noticeRadioValue.id === formData.radio_notice) {
          noticeRadioTypeItem = noticeRadioValue.name;
        }
        return noticeRadioTypeItem;
      }, []);

      const valueReceiveNotice = dataNotificationType.reduce((noticeRadioTypeItem, noticeRadioValue) => {
        if (noticeRadioValue.id === formData.receive_notice) {
          noticeRadioTypeItem = noticeRadioValue.name;
        }
        return noticeRadioTypeItem;
      }, []);

      const valueplaceEducation = educationItemsPublic.reduce((valueplaceItem, placeValue) => {
        if (placeValue.id === formData.place_of_education) {
          if (form.getFieldValue('filling_application') === 1) {
            valueplaceItem = placeValue.label1;
          } else {
            valueplaceItem = placeValue.label2;
          }
        }
        return valueplaceItem;
      }, []);

      const caringPersonName = caregiverCase.reduce((caregiverCaseItem, caregiverCaseValue, index) => {
        if (caregiverCaseValue.id === formData.caring_person_check) {
          if (caregiverCaseValue.items.length > 0) {
            const caregiverItem = caregiverCaseValue.items.reduce((currentValuecaregiverVal, currentcaregiverValue) => {
              if (currentcaregiverValue.id === formData.caring_person) {
                currentValuecaregiverVal = currentcaregiverValue.label;
              }
              return currentValuecaregiverVal;
            }, 0);
            return caregiverItem;
          }
        }
        return caregiverCaseItem;
      }, 0);

      const useSmokeRadioName = smokeItemList.reduce((smokeCaseItem, smokeCaseValue, index) => {
        if (smokeCaseValue.id === formData.use_smoke) {
          if (smokeCaseValue.items.length > 0) {
            const smokeItem = smokeCaseValue.items.reduce((currentsmokeItem, currentsmokeValue) => {
              if (currentsmokeValue.id === formData.use_smoke_radio) {
                if (form.getFieldValue('filling_application') === 1) {
                  currentsmokeItem = currentsmokeValue.label1;
                } else {
                  currentsmokeItem = currentsmokeValue.label2;
                }
              }
              return currentsmokeItem;
            }, 0);
            return smokeItem;
          }
        }
        return smokeCaseItem;
      }, 0);

      const familyStatusName = familyStatus.reduce((familyCaseItem, familyCaseValue) => {
        if (familyCaseValue.id === formData.family_status) {
          if (form.getFieldValue('filling_application') === 1) {
            familyCaseItem = familyCaseValue.label1;
          } else {
            familyCaseItem = familyCaseValue.label2;
          }
        }
        return familyCaseItem;
      }, []);

      const liveItemName = livingItems.reduce((liveCaseItem, liveCaseValue) => {
        if (liveCaseValue.id === formData.live_item) {
          if (form.getFieldValue('filling_application') === 1) {
            liveCaseItem = liveCaseValue.label1;
          } else {
            liveCaseItem = liveCaseValue.label2;
          }
        }
        return liveCaseItem;
      }, []);

      const employmentTypeName = occupationsItemsPublic.reduce((employmentCaseItem, employmentCaseValue) => {
        if (employmentCaseValue.id === formData.employment_type) {
          if (form.getFieldValue('filling_application') === 1) {
            employmentCaseItem = employmentCaseValue.label1;
          } else {
            employmentCaseItem = employmentCaseValue.label2;
          }
        }
        return employmentCaseItem;
      }, []);

      let selfCareDifficultiesTypeName = [];
      selfCareDifficulties.forEach(function (elItemsselfCareDifficulties) {
        if (elItemsselfCareDifficulties.id === formData.self_care_difficulties) {
          if (elItemsselfCareDifficulties.items.length > 0) {
            elItemsselfCareDifficulties.items.forEach(function (elItemsselfCareVal) {
              formData.self_care_difficulties_type.forEach(function (selfCareItem) {
                if (elItemsselfCareVal.id === selfCareItem) {
                  selfCareDifficultiesTypeName.push({
                    typeId: selfCareItem,
                    typeName: elItemsselfCareVal.label,
                  });
                }
              });
            });
          }
        }
      });

      let houseHoldDifficultiesTypeName = [];
      houseHoldItem.forEach(function (elItemsHouseHoldItem) {
        if (elItemsHouseHoldItem.id === formData.house_hold_difficulties) {
          if (elItemsHouseHoldItem.items.length > 0) {
            elItemsHouseHoldItem.items.forEach(function (elItemHouseHoldVal) {
              formData.house_hold_difficulties_type.forEach(function (selfHouseHold) {
                if (elItemHouseHoldVal.id === selfHouseHold) {
                  houseHoldDifficultiesTypeName.push({
                    typeId: selfHouseHold,
                    typeName: elItemHouseHoldVal.label,
                  });
                }
              });
            });
          }
        }
      });

      let movementDifficultiesTypeName = [];
      movementDifficulties.forEach(function (elItemsMovementDifficulties) {
        if (elItemsMovementDifficulties.id === formData.movement_difficulties) {
          if (elItemsMovementDifficulties.items.length > 0) {
            elItemsMovementDifficulties.items.forEach(function (elItemMovementDifficultiesVal) {
              formData.movement_difficulties_type.forEach(function (selfMovement) {
                if (elItemMovementDifficultiesVal.id === selfMovement) {
                  movementDifficultiesTypeName.push({
                    typeId: selfMovement,
                    typeName: elItemMovementDifficultiesVal.label,
                  });
                }
              });
            });
          }
        }
      });

      let bodyPositionTypeName = [];
      bodyPosition.forEach(function (elItemsBodyPosition) {
        if (elItemsBodyPosition.id === formData.body_position) {
          if (elItemsBodyPosition.items.length > 0) {
            elItemsBodyPosition.items.forEach(function (elItemBodyVal) {
              formData.body_position_type.forEach(function (selfBody) {
                if (elItemBodyVal.id === selfBody) {
                  bodyPositionTypeName.push({
                    typeId: selfBody,
                    typeName: elItemBodyVal.label,
                  });
                }
              });
            });
          }
        }
      });

      let communicationDifficultiesTypeName = [];
      communicationDifficulties.forEach(function (elItemsCommunicationDifficulties) {
        if (elItemsCommunicationDifficulties.id === formData.communication_difficulties) {
          if (elItemsCommunicationDifficulties.items.length > 0) {
            elItemsCommunicationDifficulties.items.forEach(function (elItemCommunityVal) {
              formData.communication_difficulties_type.forEach(function (selfCommunity) {
                if (elItemCommunityVal.id === selfCommunity) {
                  communicationDifficultiesTypeName.push({
                    typeId: selfCommunity,
                    typeName: elItemCommunityVal.label,
                  });
                }
              });
            });
          }
        }
      });

      let useTransportTypeName = [];
      useTransportList.forEach(function (elItemsuseTransportList) {
        if (elItemsuseTransportList.id === formData.use_transport) {
          if (elItemsuseTransportList.items.length > 0) {
            elItemsuseTransportList.items.forEach(function (elItemTransportVal) {
              formData.use_transport_type.forEach(function (selfTransport) {
                if (elItemTransportVal.id === selfTransport) {
                  if (form.getFieldValue('filling_application') === 1) {
                    useTransportTypeName.push({
                      typeId: selfTransport,
                      typeName: elItemTransportVal.label1,
                    });
                  } else {
                    useTransportTypeName.push({
                      typeId: selfTransport,
                      typeName: elItemTransportVal.label2,
                    });
                  }
                }
              });
            });
          }
        }
      });

      let likeLegalRepresentativeName;
      let legalRepresentativeInCaseName;
      optionsListCase?.forEach(function (eloptionsListCase) {
        if (eloptionsListCase.id === formData.filling_application) {
          if (eloptionsListCase.items_select.length > 0) {
            eloptionsListCase.items_select.forEach(function (elItemSelect) {
              if (elItemSelect.id === formData.select_item1) {
                likeLegalRepresentativeName = elItemSelect.label;
                if (elItemSelect.select_case.length > 0) {
                  elItemSelect.select_case.forEach(function (elLastSel) {
                    if (elLastSel.id === formData.select_item123) {
                      legalRepresentativeInCaseName = elLastSel.label;
                    }
                  });
                }
              }
            });
          }
        }
      });

      let stayingLocation;
      let ussCenter;

      if (formData.complete_evolution === 1) {
        let medicalCenterName;
        evaluationList.forEach(function (evItem) {
          if (evItem.id === formData.complete_evolution) {
            if (evItem.evalution_select.length > 0) {
              evItem.evalution_select.forEach(function (evSelectMap) {
                if (evSelectMap.id === formData.complete_evolution_mediacal) {
                  healthsList.forEach(function (ItemsMapEv) {
                    if (ItemsMapEv.id === formData.complete_mediacal) {
                      medicalCenterName = ItemsMapEv.name;
                    }
                  });
                }
              });
            }
          }
        });

        stayingLocation = {
          stayingLocationId: formData.complete_evolution_mediacal,
          stayingLocationName:
            formData.complete_evolution_mediacal === 1 ? 'Բժշկական հաստատություն' : 'Փաստացի գտնվելու վայրում',
          medicalInstitutions:
            formData.complete_evolution_mediacal === 1
              ? {
                  medicalInstitutionId: formData.complete_mediacal,
                  medicalInstitutionName: medicalCenterName,
                  medicalInstitutionAddress: null,
                }
              : null,
          actualResidence:
            formData.complete_evolution_mediacal === 2
              ? {
                  actualResidenceAddress: {
                    regionId: formData.regionName,
                    communityId: formData.communityHiddenName,
                    settlementId: formData.settlementHiddenName,
                    region: getCurrentRegionName,
                    community: formData.communityName,
                    settlement: formData.settlementName,
                    street: formData.streetName,
                    buildingType: formData.structureTypeName ? formData.structureTypeName : null,
                    building: formData.structureName,
                    apartment: formData.apartmentName ? formData.apartmentName : null,
                  },
                }
              : null,
        };
        ussCenter = null;
      } else if (formData.complete_evolution === 2) {
        ussCenter = {
          ussCenterId: formData.officeName,
          ussCenterName: formData.complete_centers_select,
        };
        stayingLocation = null;
      }

      let familyCompositionList = [];
      if (formData?.family_composition?.length > 0) {
        formData.family_composition.forEach(function (selfFamily) {
          familyComposition.forEach(function (elItemsfamilyCompositionList) {
            if (elItemsfamilyCompositionList.id === selfFamily) {
              familyCompositionList.push({
                typeId: selfFamily,
                typeName: elItemsfamilyCompositionList.label,
              });
            }
          });
        });
      } else {
        familyCompositionList = null;
      }

      const firstPageDifficulties = {
        typeId: idRadioButton,
        typeName: formData.radio_button_name,
      };

      let formJsonData;
      let url;

      // Causative change
      const actualAddress = {
        regionId: formData.regionName,
        communityId: formData.communityHiddenName,
        settlementId: formData.settlementHiddenName,
        region: getCurrentRegionName,
        community: formData.communityName,
        settlement: formData.settlementName,
        street: formData.streetName,
        buildingType: formData.structureTypeName ? formData.structureTypeName : null,
        building: formData.structureName,
        postIndex: formData.postIndexAddress ? formData.postIndexAddress : null,
        apartment: formData.apartmentName ? formData.apartmentName : null,
      };
      if ((selection === NUMBERS_USER.ITEMCASETHREE && subtypeId === 9) || subtypeId === 8) {
        let conductAssessmentAtLocation = form.getFieldValue('assessment_data');
        formJsonData = {
          iProvided: formData.provided_field ? true : false,
          IsMedicalDocumentAttached: false,
          IApproved: formData.approved_field ? true : false,
          applicationForm: {
            conductAssessmentAtLocation: {
              ...conductAssessmentAtLocation,
              stayingLocation: {
                ...conductAssessmentAtLocation.stayingLocation,
                actualResidence: {
                  actualResidenceAddress: actualAddress,
                },
              },
            },
            electronicCaseNumber: null,
            firstName: formData.Person_firstName,
            lastName: formData.Person_lastName,
            patronymicName: formData.Person_patronymicName ? formData.Person_patronymicName : null,
            documentNumber: null,
            ssn: formData.Person_ssn,
            documentType: null,
            birthDate: moment(formData.Person_birthDate).format('YYYY-MM-DD'),
            gender: null,
            phoneNumber: formData.Person_phoneNumber,
            email: formData.Person_email ? formData.Person_email : null,
            registrationAddress: null,
            actualAddress,
            primaryAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEONE ? firstPageDifficulties : null,
            doubleAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETWO ? firstPageDifficulties : null,
            reassessmentForPurposeOfAppeal:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETHREE ? firstPageDifficulties : null,
            determineDeathRelationShip:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEFOURE ? firstPageDifficulties : null,
            legalRepresentative: legalRepresentativeItem,
            fillingApplication: {
              typeId: formData.filling_application,
              typeName: formData.filling_application === 1 ? 'Ինձ համար' : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
            },
            likeLegalRepresentative:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item1,
                    typeName: likeLegalRepresentativeName,
                  }
                : null,
            legalRepresentativeInCase:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item123,
                    typeName: legalRepresentativeInCaseName,
                  }
                : null,
            preferGetNotified: {
              typeId: formData.radio_notice,
              typeName: valueRadioNotice,
            },
            preferReceiveApplications: {
              typeId: formData.receive_notice,
              typeName: valueReceiveNotice,
            },
          },
        };
        if (subtypeId === 9) {
          createAppeal(formJsonData);
        } else if (subtypeId === 8) {
          createPSPAAppeal(formJsonData);
        }
        return;
      }
      if (selection === NUMBERS_USER.ITEMCASETHREE) {
        formJsonData = {
          iProvided: formData.provided_field ? true : false,
          IsMedicalDocumentAttached: false,
          IApproved: formData.approved_field ? true : false,
          applicationForm: {
            electronicCaseNumber: null,
            firstName: formData.Person_firstName,
            lastName: formData.Person_lastName,
            patronymicName: formData.Person_patronymicName ? formData.Person_patronymicName : null,
            documentNumber: null,
            ssn: formData.Person_ssn,
            documentType: null,
            birthDate: moment(formData.Person_birthDate).format('YYYY-MM-DD'),
            gender: null,
            phoneNumber: formData.Person_phoneNumber,
            email: formData.Person_email ? formData.Person_email : null,
            registrationAddress: null,
            actualAddress: {
              regionId: formData.regionName,
              communityId: formData.communityHiddenName,
              settlementId: formData.settlementHiddenName,
              region: getCurrentRegionName,
              community: formData.communityName,
              settlement: formData.settlementName,
              street: formData.streetName,
              buildingType: formData.structureTypeName ? formData.structureTypeName : null,
              building: formData.structureName,
              postIndex: formData.postIndexAddress ? formData.postIndexAddress : null,
              apartment: formData.apartmentName ? formData.apartmentName : null,
            },

            primaryAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEONE ? firstPageDifficulties : null,
            doubleAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETWO ? firstPageDifficulties : null,
            reassessmentForPurposeOfAppeal:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETHREE ? firstPageDifficulties : null,
            determineDeathRelationShip:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEFOURE ? firstPageDifficulties : null,
            legalRepresentative: legalRepresentativeItem,
            fillingApplication: {
              typeId: formData.filling_application,
              typeName: formData.filling_application === 1 ? 'Ինձ համար' : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
            },
            likeLegalRepresentative:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item1,
                    typeName: likeLegalRepresentativeName,
                  }
                : null,
            legalRepresentativeInCase:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item123,
                    typeName: legalRepresentativeInCaseName,
                  }
                : null,
            preferGetNotified: {
              typeId: formData.radio_notice,
              typeName: valueRadioNotice,
            },
            preferReceiveApplications: {
              typeId: formData.receive_notice,
              typeName: valueReceiveNotice,
            },
            decision: {
              decisionId: formData.decisionId,
            },
          },
        };

        reassessment(formJsonData);
        //! Check grammar when declaring var-s
      } else if (selection === NUMBERS_USER.ITEMCASEFOURE) {
        formJsonData = {
          iProvided: formData.provided_field ? true : false,
          applicationForm: {
            electronicCaseNumber: null,
            firstName: formData.Person_firstName,
            lastName: formData.Person_lastName,
            patronymicName: formData.Person_patronymicName ? formData.Person_patronymicName : null,
            documentNumber: null,
            ssn: formData.Person_ssn,
            documentType: null,
            birthDate: moment(formData.Person_birthDate).format('YYYY-MM-DD'),
            gender: formData.Person_gender === GENDER_LIST_USER.M ? 'M' : 'F',
            phoneNumber: formData.Person_phoneNumber,
            email: formData.Person_email ? formData.Person_email : null,
            registrationAddress: null,
            actualAddress: {
              regionId: formData.regionName,
              communityId: formData.communityHiddenName,
              settlementId: formData.settlementHiddenName,
              region: getCurrentRegionName,
              community: formData.communityName,
              settlement: formData.settlementName,
              street: formData.streetName,
              buildingType: formData.structureTypeName ? formData.structureTypeName : null,
              building: formData.structureName,
              postIndex: formData.postIndexAddress ? formData.postIndexAddress : null,
              apartment: formData.apartmentName ? formData.apartmentName : null,
            },
            primaryAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEONE ? firstPageDifficulties : null,
            doubleAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETWO ? firstPageDifficulties : null,
            reassessmentForPurposeOfAppeal:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETHREE ? firstPageDifficulties : null,
            determineDeathRelationShip:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEFOURE ? firstPageDifficulties : null,
            legalRepresentative: legalRepresentativeItem,
            fillingApplication: {
              typeId: formData.filling_application,
              typeName: formData.filling_application === 1 ? 'Ինձ համար' : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
            },
            likeLegalRepresentative:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item1,
                    typeName: likeLegalRepresentativeName,
                  }
                : null,
            legalRepresentativeInCase:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item123,
                    typeName: legalRepresentativeInCaseName,
                  }
                : null,
            preferGetNotified: {
              typeId: formData.radio_notice,
              typeName: valueRadioNotice,
            },
            preferReceiveApplications: {
              typeId: formData.receive_notice,
              typeName: valueReceiveNotice,
            },
            applicantData: {
              firstName: formData.PersonDeath_firstName,
              lastName: formData.PersonDeath_lastName,
              patronymicName: formData.PersonDeath_patronymicName,
              ssn: formData.PersonDeath_ssn,
              birthDate: moment(formData.PersonDeath_birthDate).format('YYYY-MM-DD'),
              gender: formData.PersonDeath_gender ? formData.PersonDeath_gender : '',
              registrationAddress: {
                region: formData.PersonDeath_region ? formData.PersonDeath_region : '',
                community: formData.PersonDeath_community ? formData.PersonDeath_community : '',
                settlement: formData.PersonDeath_settlement ? formData.PersonDeath_settlement : '',
                street: formData.PersonDeath_street ? formData.PersonDeath_street : '',
                building: formData.PersonDeath_building ? formData.PersonDeath_building : '',
                buildingType: formData.PersonDeath_buildingType ? formData.PersonDeath_buildingType : '',
                apartment: formData.PersonDeath_apartment ? formData.PersonDeath_apartment : '',
              },
            },
          },
        };

        reassessmentDeathPerson(formJsonData);
      } else {
        formJsonData = {
          iProvided: formData.provided_field ? true : false,
          IsMedicalDocumentAttached: formData.file_item_true === true ? true : false,
          IApproved: formData.approved_field ? true : false,
          applicationForm: {
            electronicCaseNumber: null,
            firstName: formData.Person_firstName,
            lastName: formData.Person_lastName,
            patronymicName: formData.Person_patronymicName ? formData.Person_patronymicName : null,
            documentNumber: null,
            ssn: formData.Person_ssn,
            documentType: null,
            birthDate: moment(formData.Person_birthDate).format('YYYY-MM-DD'),
            gender: null,
            phoneNumber: formData.Person_phoneNumber,
            email: formData.Person_email ? formData.Person_email : null,
            registrationAddress: null,
            actualAddress: {
              regionId: formData.regionName,
              communityId: formData.communityHiddenName,
              settlementId: formData.settlementHiddenName,
              region: getCurrentRegionName,
              community: formData.communityName,
              settlement: formData.settlementName,
              street: formData.streetName,
              buildingType: formData.structureTypeName ? formData.structureTypeName : null,
              building: formData.structureName,
              postIndex: formData.postIndexAddress ? formData.postIndexAddress : null,
              apartment: formData.apartmentName ? formData.apartmentName : null,
            },
            legalRepresentative: legalRepresentativeItem,
            evaluationType: evaluationTypeId,
            primaryAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEONE ? firstPageDifficulties : null,
            doubleAssessmentDegree:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETWO ? firstPageDifficulties : null,
            reassessmentForPurposeOfAppeal:
              formData.primary_assessment === NUMBERS_USER.ITEMCASETHREE ? firstPageDifficulties : null,
            determineDeathRelationShip:
              formData.primary_assessment === NUMBERS_USER.ITEMCASEFOURE ? firstPageDifficulties : null,

            fillingApplication: {
              typeId: formData.filling_application,
              typeName: formData.filling_application === 1 ? 'Ինձ համար' : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
            },
            likeLegalRepresentative:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item1,
                    typeName: likeLegalRepresentativeName,
                  }
                : null,
            legalRepresentativeInCase:
              formData.filling_application !== 1
                ? {
                    typeId: formData.select_item123,
                    typeName: legalRepresentativeInCaseName,
                  }
                : null,
            allowToExtendPeriodTempDisability: null,
            determineWorkProfAbilityDegree: null,
            changeIndividualServicePlan: null,
            conductAssessmentAtLocation: {
              locationValue: formData.complete_evolution,
              locationName:
                formData.complete_evolution === 1
                  ? 'Գտնվելու վայրում'
                  : formData.complete_evolution === 2
                  ? 'ՄՍԾ կենտրոնում'
                  : '',
              stayingLocation: stayingLocation,
              ussCenter: ussCenter,
            },
            preferGetNotified: {
              typeId: formData.radio_notice,
              typeName: valueRadioNotice,
            },
            preferReceiveApplications: {
              typeId: formData.receive_notice,
              typeName: valueReceiveNotice,
            },
          },
          selfAssessment: {
            maritalTypeOver18: {
              typeId: formData.family_status,
              typeName: familyStatusName,
            },
            maritalTypeUnder18: {
              typeId: formData.live_item,
              typeName: liveItemName,
              commentForOther: formData.live_item_input ? formData.live_item_input : null,
            },
            haveChildren: {
              have: formData.have_childrens === 1 ? true : false,
              childrenCount: formData.have_childrens === 1 ? formData.count_children : null,
            },
            family: {
              familyMembers: familyCompositionList,
            },
            guardian: {
              have: formData.caring_person_check === 1 ? true : false,
              guardianType:
                formData.caring_person_check === 1
                  ? {
                      typeId: formData.caring_person,
                      typeName: caringPersonName,
                    }
                  : null,
            },
            needInfo: null,
            education: {
              typeId: formData.place_of_education,
              typeName: valueplaceEducation,
            },
            profession: {
              have: formData.profession_type === 1 ? true : false,
              whatKindOf: formData.profession_type === 1 ? formData.profession_type_input : null,
            },
            occupation: {
              typeId: formData.employment_type,
              typeName: employmentTypeName,
            },
            numberOfYearWorked: formData.work_year ? parseInt(formData.work_year) : 0,
            workPosition: formData.textarea_work,
          },
          questionnaire: {
            selfServiceDifficulties: {
              have: formData.self_care_difficulties === 1 ? true : false,
              difficultiesType: formData.self_care_difficulties === 1 ? selfCareDifficultiesTypeName : null,
            },
            houseHoldDifficulties: {
              have: formData.house_hold_difficulties === 1 ? true : false,
              difficultiesType: formData.house_hold_difficulties === 1 ? houseHoldDifficultiesTypeName : null,
            },
            movementDifficulties: {
              have: formData.movement_difficulties === 1 ? true : false,
              difficultiesType: formData.movement_difficulties === 1 ? movementDifficultiesTypeName : null,
            },
            changePositionDifficulties: {
              have: formData.body_position === 1 ? true : false,
              difficultiesType: formData.body_position === 1 ? bodyPositionTypeName : null,
            },
            useHandsDifficulties: formData.difficulty_using_hands === 1 ? true : false,
            speechDifficulties: formData.use_communication_difficulties === 1 ? true : false,
            hearingDifficulties: formData.use_hearing_difficulties === 1 ? true : false,
            visionDifficulties: formData.use_seeing_difficulty === 1 ? true : false,
            communicationDifficulties: {
              have: formData.communication_difficulties === 1 ? true : false,
              difficultiesType: formData.communication_difficulties === 1 ? communicationDifficultiesTypeName : null,
            },
            transportDifficulties: {
              have: formData.use_transport === 1 ? true : false,
              difficultiesType: formData.use_transport === 1 ? useTransportTypeName : null,
            },
            entertainmentAttendingDifficulties: null,
            biometrics: {
              height: formData.use_height,
              weight: formData.use_weight,
            },
            areYouSmoker: {
              isSmoker: formData.use_smoke === 1 ? true : false,
              ifSmoker:
                formData.use_smoke === 1
                  ? {
                      smokeYears: parseInt(formData.use_smoke_year),
                      startAge: parseInt(formData.use_smoke_age_begin),
                    }
                  : null,
              ifNotSmoker:
                formData.use_smoke === 1
                  ? null
                  : {
                      typeId: formData.use_smoke_radio,
                      typeName: useSmokeRadioName,
                    },
            },
          },
          deviceRequirement: {
            useListeningDevice: formData.use_hearing_aid === 1 ? true : false,
            useWheelchairs: formData.use_wheelchair === 1 ? true : false,
            needToAdoptBuilding: {
              need: formData.own_appartment === 1 ? true : false,
              whatKindOf: formData.own_appartment === 1 ? formData.own_appartment_input : null,
            },
            needPersonalAssistance: formData.attendant_support === 1 ? true : false,
            getOutOfHouseSupport: null,
            attitudeOfPeople: formData.attitude_of_people === 1 ? true : false,
            doYouStudy: formData.study_any_educational_institution === 1 ? true : false,
            goInstituteEveryDay:
              formData.attend_educational_institution === 1
                ? true
                : formData.attend_educational_institution === 2
                ? false
                : null,
            needToAdaptEduMaterial:
              formData.adapt_learning_materials === 1 ? true : formData.adapt_learning_materials === 2 ? false : null,
            needHelpInAttendingInstitute: formData?.support_educational_institution
              ? {
                  need: formData.support_educational_institution === 1 ? true : false,
                  whatKindOf:
                    formData.support_educational_institution === 1
                      ? formData.support_educational_institution_input
                      : null,
                }
              : null,
            doYouWork: formData.work_type === 1 ? true : false,
            needToSetupWorkEnv: formData?.adjust_the_work_environment
              ? {
                  need: formData.adjust_the_work_environment === 1 ? true : false,
                  whatKindOf:
                    formData.adjust_the_work_environment === 1 ? formData.adjust_the_work_environment_input : null,
                }
              : null,
            haveWorkingHinders: formData?.obstacles_in_the_workplace
              ? {
                  have: formData.obstacles_in_the_workplace === 1 ? true : false,
                  whatKindOf:
                    formData.adjust_the_work_environment === 1 ? formData.obstacles_in_the_workplace_input : null,
                }
              : null,
            needVocationalTraining:
              formData.requalification_courses === 1 ? true : formData.requalification_courses === 2 ? false : null,
            financiallyIndependent: formData.financially_independent === 1 ? true : false,
          },
        };

        if (
          selection === NUMBERS_USER.ITEMCASEONE &&
          (idRadioButton === APP_SUBTYPES.SUBTYPE_ONE ||
            idRadioButton === APP_SUBTYPES.SUBTYPE_TWO ||
            idRadioButton === APP_SUBTYPES.SUBTYPE_THREE)
        ) {
          url = { url: APPLICATION_URLS.CREATE_PRIMARY_ASSESSMENT_APPLICATION };
        } else if (
          selection === NUMBERS_USER.ITEMCASETWO &&
          (idRadioButton === APP_SUBTYPES.SUBTYPE_FOUR ||
            idRadioButton === APP_SUBTYPES.SUBTYPE_FIVE ||
            idRadioButton === APP_SUBTYPES.SUBTYPE_SIX ||
            idRadioButton === APP_SUBTYPES.SUBTYPE_ELEVEN)
        ) {
          url = { url: APPLICATION_URLS.CREATE_DOUBLE_APPLICATION };
        }

        mutateInfo([formJsonData, url]);
      }
    });

    if (form.getFieldValue('radio_notice') === 1 || form.getFieldValue('receive_notice') === 1) {
      setPrintButton(true);
    } else {
      setPrintButton(false);
    }
  };

  const hideModal = () => {
    setOpenModal(false);
    setButtonSubmit(false);
    navigate(`/${PATHS.HOMEPAGE}`);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && document.activeElement === buttonRefsec.current) {
      prev();
    } else if (event.key === 'Enter' && document.activeElement === buttonRef.current) {
      next();
    }
  };

  const { Step } = Steps;

  // const onChangeSteps = (value) => {
  //   //! pay attention to errors and check usage of reduce method
  //   // eslint-disable-next-line array-callback-return
  //   steps.reduce((stepsItem, stepsValue) => {
  //     if (stepsValue.optionClick === true && stepsValue.id === value) {
  //       setCurrent(value);
  //     }
  //   }, []);
  // };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <>
      {/* <DefaultHeader /> */}

      <Spin spinning={isLoading}>
        <GridCard
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {steps[current].title}{' '}
              <Button
                style={{ background: 'none', border: 'none', marginRight: 10 }}
                aria-label="Գլխավոր էջ"
                onClick={() => navigate(`/${PATHS.HOMEPAGE}`)}
              >
                <img src={HomeLogo} style={{ cursor: 'pointer' }} alt="logo" />
              </Button>
            </div>
          }
          current={current}
        >
          <Steps
            current={current}
            direction="horizontal"
            labelPlacement="horizontal"
            // onChange={onChangeSteps}
            style={{ paddingTop: 20, width: '50%', flexDirection: 'unset' }}
          >
            {steps.map((item) => (
              <Step key={item.title} style={{ width: '45px' }} aria-current={item.id === current}>
                {item?.title}
              </Step>
            ))}
          </Steps>
          <Form
            name="application-form"
            form={form}
            onKeyDown={onKeyDownHandler}
            // onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <div
              className="steps-content"
              style={{
                minHeight: '200px',
                marginTop: '16px',
                paddingTop: '20px',
                borderRadius: '2px',
              }}
            >
              {steps[current].content}
            </div>

            <div className="steps-action" style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
              {current > 0 && (
                <EdButton
                  htmlType="submit"
                  type="button"
                  style={{ margin: '0 8px' }}
                  onClick={prev}
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={buttonRefsec}
                >
                  Հետ գնալ
                </EdButton>
              )}

              {current < steps.length - 1 && <span></span>}

              {current < steps.length - 1 && getDisableItem === true && (
                <EdButton
                  htmlType="button"
                  type="button"
                  onClick={next}
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={buttonRef}
                  aria-label={`Անցնել էջ ${current + 2}`}
                >
                  Շարունակել
                </EdButton>
              )}

              {current === steps.length - 1 && getButtonSubmit && getButtonSubmit === true && (
                <EdButton
                  htmlType="submit"
                  onClick={finishProcess}
                  disabled={(selection === 4 && !areFilesOk) || infoSubmitLoading}
                >
                  Ուղարկել
                </EdButton>
              )}
            </div>
          </Form>
        </GridCard>
        {current && current !== 0 ? helpVideo : ''}
        <>
          <GridModal
            title={
              <h3>
                <CheckCircleOutlined
                  tooltip="Ծանուցում"
                  style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
                />{' '}
                Ծանուցում
              </h3>
            }
            open={openModal}
            onOk={hideModal}
            onCancel={hideModal}
            footer={false}
          >
            <p>{getConfirmText ? getConfirmText : ''}</p>

            {getPrintButton && getPrintButton === true && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
                <EdbButton href={`/${PATHS.APPLICATIONINFO_PRINT}/${getConfirmApplicationNumber}`} target="_blank">
                  Տպել
                </EdbButton>
              </div>
            )}
          </GridModal>
        </>
      </Spin>
    </>
  );
};

export default GridStep;
