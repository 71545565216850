import React from 'react';
import { Layout } from 'antd';
// import DefaultHeader from './DefaultHeader';
import DefaultContent from './DefaultContent';
import DefaultHeader from './DefaultHeader';
import SkipToMain from './SkipToMain';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ sider, header, content, footer, children }) => {
  const location = useLocation();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SkipToMain/>
      {header || location.pathname !== '/home' && <DefaultHeader />}
      <>{content || <DefaultContent>{children}</DefaultContent>}</>
      {footer || <footer></footer>}
    </Layout>
  );
};

export default MainLayout;
