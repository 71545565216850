import styled from 'styled-components';
import { Select } from 'antd';

import './index.css';

const EdbSelect = styled(Select)`
  &.ant-select {
    .ant-select-selector {
      height: 45px;
      border: 1px solid #c0c1c2;
      border-radius: 10px;
      padding: 4px 15px;
    }

    .ant-select-selection-item {
      @media (max-width: 800px) {
        overflow-x: scroll;
        text-overflow: clip;
        margin-right: 10px;
      }
    }

    .ant-select-item-option-content {
      overflow-x: scroll;
      text-overflow: clip;
      word-wrap: break-word;
      height: fit-content;
    }

    .ant-select-arrow {
      color: #c0c1c2;
    }

    .ant-select-selection-placeholder {
      color: #555965;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #3c9d99;
    }

    &.ant-select-focused .ant-select-selector {
      box-shadow: 0 0 0 0.1875rem #bd13b8 !important;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      min-height: 45px;
      height: 100%;
    }
  }
`;

export * from './constants';

export default EdbSelect;
