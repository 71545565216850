import { useMutation } from '@tanstack/react-query';
import client from '../clientsubmitapplication';

export const CREATE_CAUSATIVE_APPEAL = '/EDisability/CreateCauseAppeal';
export const CREATE_PSPA_APPEAL = '/EDisability/CreatePSPAppeal';

const useCreateAppeal = (url, options = {}) => {
  const mutation = useMutation((values) => {
    return client.post(url, values);
  }, options);
  return mutation;
};

export default useCreateAppeal;
