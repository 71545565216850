import styled, {css} from "styled-components";
import {Radio} from 'antd';

const defaultStyle = css`
.ant-radio-checked .ant-radio-inner{
  border-color: #0B847F ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #0B847F;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #0B847F ;
}
`;

const EdbRadio = styled(Radio)`
  ${defaultStyle}
`;

const Group = styled(Radio.Group)`
  ${defaultStyle}
`;



export default EdbRadio;