import { useState } from 'react';
import { Card, Col, Divider, Row } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import VerticalSpace from '../../components/Layout/VerticalSpace';
import { FormRequiredInfo } from '../../components/Typography/FormRequiredInfo';
import EdbForm from '../../components/Form';
import EdbInput from '../../components/Input';
import EdbButton from '../../components/Button';
import EdbDatePicker from '../../components/DatePicker';
import moment from 'moment';
import { WarningPassportFill } from '../../components/Typography/WarningPassportFill';
import useVerifyMilitaryData from '../../api/Verify/useVerifyMilitaryData';
import { Text } from '../../components/Typography/text';
import GridModal from '../../components/GridStep/GridModal/GridModal';

const CardStyle = styled(Card)`
  && {
    .ant-card-head {
      background-color: #0b847f;
    }
  }
`;

export const Verify = () => {
  const [result, setResult] = useState();
  const [form] = EdbForm.useForm();
  const { mutate } = useVerifyMilitaryData({
    onSuccess: ({ data }) => {
      setResult(data);
    },
  });

  const onFinish = ({ lname, fname, ...values }) => {
    mutate({
      ...values,
      firstName: fname,
      lastName: lname,
      birthdate: moment(values.birthdate).format('YYYY/MM/DD'),
    });
  };
  return (
    <VerticalSpace>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} md={18}>
          <CardStyle
            title={<Text color="#ffffff">Պաշտպանության նախարարություն</Text>}
            bordered={false}
            style={{ width: '100%' }}
          >
            <VerticalSpace>
              <FormRequiredInfo />
              <EdbForm
                name="signin"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
              >
                <EdbForm.Item label="ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար" required>
                  <Row gutter={20}>
                    <Col span={12}>
                      <EdbForm.Item name="ssn" rules={[{ required: true }]}>
                        <EdbInput />
                      </EdbForm.Item>
                    </Col>
                    <Col span={5}>
                      <EdbButton htmlType="submit" block>
                        Որոնել
                      </EdbButton>
                    </Col>
                  </Row>
                </EdbForm.Item>
                <Divider />
                <Row gutter={20}>
                  <Col span={8}>
                    <EdbForm.Item label="Անուն" name="fname" rules={[{ required: true }]}>
                      <EdbInput />
                    </EdbForm.Item>
                  </Col>
                  <Col span={8}>
                    <EdbForm.Item name="lname" label="Ազգանուն" rules={[{ required: true }]}>
                      <EdbInput />
                    </EdbForm.Item>
                  </Col>
                  <Col span={8}>
                    <EdbForm.Item label="Հայրանուն" name="patronymicName">
                      <EdbInput />
                    </EdbForm.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <EdbForm.Item name="decisionNumber" label="Որոշման համար" rules={[{ required: true }]}>
                      <EdbInput />
                    </EdbForm.Item>
                  </Col>
                  <Col span={12}>
                    <EdbForm.Item name="birthdate" label="Ծննդյան ամսաթիվ" rules={[{ required: true }]}>
                      <EdbDatePicker
                        size="large"
                        format="DD/MM/yyyy"
                        placeholder="__/__/____ "
                        style={{ background: 'none', borderRadius: 10, width: '100%' }}
                        disabledDate={(d) => {
                          return d > moment().add();
                        }}
                      />
                    </EdbForm.Item>
                  </Col>
                </Row>
              </EdbForm>
              <WarningPassportFill />
            </VerticalSpace>
          </CardStyle>
        </Col>
      </Row>
      <GridModal
        title={
          <h3>
            <CheckCircleOutlined
              tooltip="Ծանուցում"
              style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
            />
            Ծանուցում
          </h3>
        }
        open={result === false || result === true}
        afterClose={() => {
          setResult();
        }}
        onCancel={() => {
          setResult();
        }}
        footer={false}
      >
        {result ? 'Որոշումը առկա է' : 'Որոշումը առկա չէ'}
      </GridModal>
    </VerticalSpace>
  );
};
