import { Card, Col, Form, Input, Radio, Row } from 'antd';
import React, { useState } from 'react';
import VerticalSpace from '../../Layout/VerticalSpace';
import PersonSsnReassessment from '../PersonInfo/PersonSsnReassessment';
import { DICTIONARY_URL } from '../../../helpers/constants';
import PersonAddress from '../PersonInfo/PersonAddress';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddDeleteTableRows from '../GeneralInfo/AddDeleteTableRows';
import { NUMBERS_USER, approvedList, formItemLayout, providedList, requiredMessage } from '../../../helpers/userapp';
import EdbCheckbox from '../../Checkbox';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import EdbRadio from '../../Radio';
import OptionTitle from '../OptionTitle';
import { ValuationPlaceSelect } from './ValuationPlaceSelect';
import { noop } from '../../../helpers/utils';
import EdbCard from '../../Card';

// subtypeId: 8 - PSPA Appeal 9 - Causative change
const CausativeChange = ({ subtypeId }) => {
  const [isEmailNull, setEmailNull] = useState(false);
  const [isPostIndexNull, setPostIndexNull] = useState(false);
  const form = Form.useFormInstance();
  const { data: dataNotificationType } = useGetDictionaryPublic(DICTIONARY_URL.GET_NOTIFICATION_TYPES);

  const arrayListValTitles = OptionTitle(true);

  const changeRadioNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  const changeReceiveNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  return (
    <VerticalSpace>
      <EdbCard
        title="Վերագնահատվող անձի տվյալներ"
        style={{
          marginBottom: 10,
          borderTop: '1px solid #C0C1C2',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <PersonSsnReassessment
          title="Վերագնահատվող անձի տվյալներ"
          checkEmail={isEmailNull}
          checkHasDecision={false}
          subtypeId={subtypeId}
        />
      </EdbCard>

      <PersonAddress checkPostIndex={isPostIndexNull} checkCommunityId={noop} />
      <ValuationPlaceSelect />
      <div key={1}>
        <EdbCard
          title="Կցել փաստաթուղթ"
          style={{
            marginBottom: 10,
            borderTop: 0,
            borderBottom: '1px solid #C0C1C2',
            borderRight: 0,
            borderLeft: 0,
            padding: 0,
          }}
          className="form__person_document"
        >
          <AddDeleteTableRows requiredType={subtypeId === 9 ? 2 : 1} subtypeId={subtypeId} />
          <Form.Item
            name="file_item_true"
            rules={[
              {
                required: true,
                message:
                  subtypeId === 9
                    ? 'Պատճառահետևանքային կապը հաստատող փաստաթղթի կցումը պարտադիր է'
                    : 'Բժշկական փաստաթղթի կցումը, որի հիման վրա պետք է փոփոխվի Ծառայությունների անհատական ծրագիրը (ԾԱԾ), պարտադիր է:',
              },
            ]}
          >
            <Input type="hidden" value={true} />
          </Form.Item>
          <p style={{ marginTop: '15px', color: '#555965' }}>
            <ExclamationCircleOutlined />
            {subtypeId === 9
              ? 'Պատճառահետևանքային կապը հաստատող փաստաթղթի կցումը պարտադիր է'
              : 'Բժշկական փաստաթղթի կցումը, որի հիման վրա պետք է փոփոխվի Ծառայությունների անհատական ծրագիրը (ԾԱԾ), պարտադիր է:'}
          </p>
          <div style={{ width: '50%' }}>
            <p style={{ marginTop: '15px', color: '#555965' }}>
              <ExclamationCircleOutlined /> Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
            </p>
          </div>
        </EdbCard>

        <Row
          style={{
            gap: 10,
            borderBottom: '1px solid #C0C1C2',
          }}
        >
          <Col span={11}>
            <EdbCard
              title={arrayListValTitles[1]}
              style={{
                marginBottom: 10,
                border: 0,
              }}
              className="form__person_place"
              tabIndex={0}
            >
              <Form.Item {...formItemLayout} name="radio_notice" rules={[{ required: true, message: requiredMessage }]}>
                <Radio.Group className="radlo_button" onChange={(e) => changeRadioNotice(e)}>
                  {dataNotificationType.map((itemNotice) => (
                    <EdbRadio
                      key={itemNotice.id}
                      value={itemNotice.id}
                      name={itemNotice.id}
                      className="radlo_button"
                      disabled={!itemNotice.isActive}
                    >
                      {itemNotice.name}
                    </EdbRadio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </EdbCard>
          </Col>
          <Col span={12}>
            <EdbCard
              title={arrayListValTitles[29]}
              style={{
                marginBottom: 10,
                border: 0,
              }}
              className="form__person_place"
              tabIndex={0}
            >
              <Form.Item
                {...formItemLayout}
                name="receive_notice"
                rules={[{ required: true, message: requiredMessage }]}
              >
                <Radio.Group className="radlo_button" onChange={(e) => changeReceiveNotice(e)}>
                  {dataNotificationType.map((itemNotice) => (
                    <EdbRadio
                      key={itemNotice.id}
                      value={itemNotice.id}
                      name={itemNotice.id}
                      className="radlo_button"
                      disabled={!itemNotice.isActive}
                    >
                      {itemNotice.name}
                    </EdbRadio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </EdbCard>
          </Col>
        </Row>
        <EdbCard
          style={{
            marginBottom: '-15px',
            marginTop: 20,
            borderTop: 30,
            border: 0,
            padding: 0,
          }}
          className="form__person_place"
        >
          <Form.Item {...formItemLayout} name="provided_field" rules={[{ required: true, message: requiredMessage }]}>
            <EdbCheckbox.Group options={providedList} />
          </Form.Item>
        </EdbCard>
        <EdbCard
          style={{
            marginBottom: 0,
            marginTop: 0,
            borderTop: 30,
            border: 0,
            padding: 0,
          }}
          className="form__person_place"
        >
          <Form.Item {...formItemLayout} name="approved_field" rules={[{ required: true, message: requiredMessage }]}>
            <EdbCheckbox.Group options={approvedList} />
          </Form.Item>
        </EdbCard>
      </div>
    </VerticalSpace>
  );
};

export default CausativeChange;
