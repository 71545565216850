import React from 'react';
import { Button, Layout, Result } from 'antd';
import DefaultHeader from '../../components/Layout/DefaultHeader';
import styled from 'styled-components';

const MessageTitle = styled(Result)`
  .ant-result-icon {
    display: none;
  }

  .ant-result-title {
    font-size: 24px;
    line-height: 32px;
    color: #091024;

    a {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      padding: 0;
      color: #0b847f;
    }
  }

  .ant-result-subtitle {
    font-weight: 700;
    font-size: 350px;
    line-height: 476px;
    color: #aaaaaa;
  }

  .ant-result-extra {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    color: #aaaaaa;
  }
`;

const HomeButton = styled(Button)``;

const PageNotFound = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <DefaultHeader />
    <Layout style={{ backgroundColor: '#ffffff' }}>
      <MessageTitle
        title={
          <>
            The page you are looking for doesn’t exist or other <br /> error occurred. Go to{' '}
            <HomeButton type="link" href="/">
              Home Page.
            </HomeButton>
          </>
        }
        subTitle="404"
        extra="Page Not Found"
      />
    </Layout>
  </Layout>
);

export default PageNotFound;
