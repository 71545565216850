import styled from 'styled-components';
import { Card } from 'antd';

const GridCard = styled(Card)`
  border: 1px solid #dddfe1;
  border-radius: 10px;
  margin-bottom: 10px;
  
   
   .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background-color: #0b847f;
        
        .ant-steps-icon {
            color: #FFF;
        }
   }
   
   .ant-steps-item-icon{
      line-height: 30px;
   }
   
   .ant-steps-item-title:after {
        background-color: #C0C1C2;
        height 2px;
   }
   
   .active_bg {
        background-color: #0b847f;
        color: #FFF !important;
   }
   
   .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
        background-color: #0b847f;
   }
   
   .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #0b847f;
        border-color: #0b847f;
        
        .ant-steps-icon {
            color: #FFF;
        }
   }
   
   
   
   .radlo_button {
        .ant-radio-wrapper {
            display: block;
            margin-bottom: 25px;
            font-weight: 400;
        }
        
        .ant-space-vertical {
            margin-top: 15px;
            margin-left: 20px;
        }
   }
  
  
  .ant-radio-checked .ant-radio-inner {
    border-color: #0b847f;
  }
  
  .ant-radio-inner:after {
    background-color: #0b847f;
  }
  
  .ant-card-head {
    background: #0b847f;
    border-radius: 10px 10px 0px 0px ;
    min-height: ${props => props.title?.length ? '31px' : '20px'} ;
    color: #FFFFFF;
    font-size: 21px;
  }
  
  .form__person_place {
    .ant-card-head {
        background: #FFF;
        color: #0b847f;
        padding: 0;
        border: 0;
    }
    
    .ant-card-body {
        padding-top: 0;
        padding-left: 0;
    }
    
    .ant-radio-wrapper {
        margin-bottom: 15px;
        width: fit-content;
    }
    
    .ant-checkbox-wrapper {
        margin-left: 0;
        display: flex;
        margin-bottom: 10px;
    }
  }
  
    .form__person_family {
        .ant-card-head {
            background: #FFF;
            color: #0b847f;
            padding: 0;
            border: 0;
        }
        
        .ant-card-body {
            padding-top: 0;
            padding-left: 0;
        }
        
        .ant-radio-wrapper {
            margin-bottom: 15px;
        }
        
        .ant-checkbox-group {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            
            label {
                margin-bottom: 20px;
            }
        }
    }
  
  .form__person_smoke {
    .ant-card-head {
        background: #FFF;
        color: #0b847f;
        padding: 0;
        border: 0;
    }
    
    .ant-card-body {
        padding-top: 0;
        padding-left: 0;
    }
    
    .ant-radio-group {
        display: grid;
        margin-left: 30px;
        
        label {
            margin-bottom: 20px;
        }
    }
  }
  
  .form__person_document {
  
    .ant-card-body {
        padding: 0;
    }
    
    .ant-card-head {
        background: #FFF;
        color: #0b847f;
        padding: 0;
        border: 0;
    }
  }
  
  #application-form_radio_button_name {
        display: grid;
    }
    
  
  .grid_row {
    label {
        .ant-radio {
            display: none;
        }
    }
    
    .ant-col {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        border: 1px solid #C0C1C2;
        border-radius: 10px;
        color: #091024;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 0 80px;
        text-align: center;
        cursor: pointer;
    }
  }
  
  .grid-infoCircle {
    padding-top: 20px;
    color: #555965;
    svg {
        font-size: 18px;
    }

  }
`;

export default GridCard ;
