import { useQuery } from '@tanstack/react-query';
import client from '../clientsubmitapplication';

const url = `/EDisability/GetDecisionNumber`;

const useGetDecisionNumber = (params, options = { enabled: false }) => {
  const result = useQuery([url, params], () => client.get(url, { params }), {
    ...options,
    select: (data) => data.data,
  });

  const { data, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? data : {},
  };
};
export default useGetDecisionNumber;
