import React, { useState, useEffect } from 'react';
import { Form, Radio, Select, Row, Col, Input } from 'antd';
import EdbRadio from '../../Radio';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { formItemLayout, evaluationList, requiredMessage, NUMBERS_USER } from '../../../helpers/userapp';
import EdbSelect from '../../Select';
import OptionTitle from '../OptionTitle';
import { DICTIONARY_URL } from '../../../helpers/constants';
import PersonSsn from '../PersonInfo/PersonSsn';
import PersonAddress from '../PersonInfo/PersonAddress';
import VerticalSpace from '../../Layout/VerticalSpace';
import AddDeleteTableRows from './AddDeleteTableRows';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import EdbCard from '../../Card';

const { Option } = Select;

export const GeneralInfo = ({ subtypeId }) => {
  const form = Form.useFormInstance();
  const [isEvSelect, getEvSelect] = useState(evaluationList);
  const [isEmailNull, setEmailNull] = useState(false);
  const [isPostIndexNull, setPostIndexNull] = useState(false);
  const [getComId, setComId] = useState(false);
  const [placeId, setPlaceId] = useState();
  const [secPlaceId, setSecPlaceId] = useState();
  const [officeId, setOfficeId] = useState();
  const [hospitalId, setHospitalId] = useState();
  const [officesList, setOfficesList] = useState([]);
  const { data: healthsList } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_HEALTHS);
  const { data: dataNotificationType } = useGetDictionaryPublic(DICTIONARY_URL.GET_NOTIFICATION_TYPES);

  useGetDictionaryPublic(
    DICTIONARY_URL.GET_ALL_OFFICES,
    {
      enabled: !!getComId,
      onSuccess: (data) => {
        setOfficesList(data);
      },
    },
    { communityId: getComId }
  );

  const listClickEvSelect = (event) => {
    setPlaceId(event);
    form.setFieldsValue({
      complete_mediacal_select: null,
      complete_evolution_mediacal: null,
      complete_mediacal: null,
      carry_assessment_region: null,
      carry_assessment_community: null,
      carry_assessment_settlement: null,
      complete_centers_select: null,
    });

    const getSelectAll = isEvSelect.map((itemCaseSelectAll) => {
      itemCaseSelectAll.optionClick = false;
      if (itemCaseSelectAll.id === event) {
        itemCaseSelectAll.optionClick = true;
      }
      return itemCaseSelectAll;
    });
    getEvSelect(getSelectAll);
  };

  const listClickEvSelectOffice = (event) => {
    const ussId = officesList.reduce((officesListTypeItem, officesListValue) => {
      if (officesListValue.name === event) {
        officesListTypeItem = officesListValue.id;
      }
      return officesListTypeItem;
    }, 0);
    form.setFieldsValue({
      officeName: ussId,
    });
    setOfficeId(event);
  };

  const listClickEvSelectLast = (event) => {
    const getSelectLast = isEvSelect.map((lastSelectItem) => {
      lastSelectItem.evalution_select.map((lastSelEv) => {
        lastSelEv.optionClick = false;
        if (lastSelEv.id === event) {
          lastSelEv.optionClick = true;
        }

        return lastSelEv;
      });
      return lastSelectItem;
    });
    getEvSelect(getSelectLast);
    setSecPlaceId(event);
  };

  const changeRadioNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  const changeReceiveNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === null &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE &&
      form.getFieldValue('Representative_email') === undefined &&
      form.getFieldValue('Person_email') === undefined
    ) {
      setEmailNull(true);
    } else if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    } else {
      setEmailNull(false);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    } else {
      setPostIndexNull(false);
    }
  };

  //! check usage of the hook
  useEffect(() => {
    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    }
  });

  const handleSelectHospital = (value) => {
    setHospitalId(value)
    
  }

  const arrayListValTitles = OptionTitle(true);
  return (
    <>
      <VerticalSpace>
        <EdbCard
          title={`${subtypeId === 4 ? 'Վերագնահատվող անձի տվյալներ' : 'Գնահատվող անձի տվյալներ'}`}
          style={{
            marginBottom: 10,
            borderTop: '1px solid #C0C1C2',
            borderBottom: '1px solid #C0C1C2',
            borderRight: 0,
            borderLeft: 0,
          }}
          className="form__person_place"
        >
          <PersonSsn title={`${subtypeId === 4 ? 'Վերագնահատվող անձի տվյալներ' : 'Գնահատվող անձի տվյալներ'}`} checkEmail={isEmailNull} />
        </EdbCard>
      </VerticalSpace>
      <PersonAddress checkPostIndex={isPostIndexNull} checkCommunityId={setComId} />
      <EdbCard
        title={arrayListValTitles[0]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={11} md={7}>
          <Form.Item
            {...formItemLayout}
            name="complete_evolution"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                placeId
                  ? 'ընտրված գնահատման վայր' + isEvSelect.find((child) => child.value === placeId)?.label
                  : 'Ընտրացանկից ընտրեք թե որտե՞ղ եք ցանկանում իրականացնել գնահատումը'
              }
              onChange={(e) => listClickEvSelect(e)}
            >
              {isEvSelect.map((evItem) => (
                <Option key={evItem.value} aria-label={evItem.ariaLabel} value={evItem.value}>
                  {evItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        {isEvSelect.map((evItem) =>
          evItem.optionClick &&
          evItem.evalution_select.length === NUMBERS_USER.ITEMCASEZERO &&
          evItem.id === NUMBERS_USER.ITEMCASETWO ? (
            <div key={1}>
              <Col span={7} xs={22} sm={11} md={7}>
                <Form.Item
                  {...formItemLayout}
                  name="complete_centers_select"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbSelect
                    placeholder="Ընտրել"
                    aria-label={
                      officeId
                        ? 'ընտրված գնահատման վայր' + officesList.find((child) => child.name === officeId)?.name
                        : 'Ընտրացանկից ընտրեք Միասնական Սոցիալական Ծառայության կենտրոնը'
                    }
                    onChange={(e) => listClickEvSelectOffice(e)}
                  >
                    {officesList &&
                      officesList.map((officesItem) => (
                        <Option key={officesItem.name} value={officesItem.name}>
                          {officesItem.name}
                        </Option>
                      ))}
                  </EdbSelect>
                </Form.Item>
              </Col>
              <Form.Item name="officeName">
                <Input type="hidden" />
              </Form.Item>
            </div>
          ) : evItem.optionClick && evItem.evalution_select.length > 0 && evItem.id === NUMBERS_USER.ITEMCASEONE ? (
            <div key={2}>
              <Col span={7} xs={22} sm={11} md={7}>
                <Form.Item
                  {...formItemLayout}
                  name="complete_evolution_mediacal"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbSelect
                    placeholder="Ընտրել"
                    aria-label={
                      secPlaceId
                        ? 'ընտրված գնահատման վայր' +
                          evItem.evalution_select.find((child) => child.id === secPlaceId)?.label
                        : 'Ընտրացանկից ընտրեք թե ո՞րտեղ եք ցանկանում գնահատում իրականցնել։ Գտնվելու վայրում գնահատումը կարող է իրականացվել միայն այն դեպքում, եթե դիմումատուն գտնվում է այնպիսի ծանր վիճակում, որ ի վիճակի չէ այցելել Միասնական Սոցիալական Ծառայության կենտրոն'
                    }
                    onChange={(e) => listClickEvSelectLast(e)}
                    style={{ width: '340px' }}
                  >
                    {evItem.evalution_select.map((evItemMap) => (
                      <Option key={evItemMap.id} aria-label={evItemMap.ariaLabel} value={evItemMap.id}>
                        {evItemMap.label}
                      </Option>
                    ))}
                  </EdbSelect>
                </Form.Item>
              </Col>

              {evItem.evalution_select.map(
                (evItemMap) =>
                  evItemMap.optionClick &&
                  evItemMap.id === NUMBERS_USER.ITEMCASEONE && (
                    <div key={3}>
                      <Col span={7} xs={22} sm={11} md={7}>
                        <Form.Item
                          {...formItemLayout}
                          name="complete_mediacal"
                          rules={[{ required: true, message: requiredMessage }]}
                        >
                          <EdbSelect
                            placeholder="Ընտրել"
                            aria-label={
                              hospitalId
                                ? 'ընտրված բժշկական հաստատություն' + healthsList.find((child) => child.id === hospitalId)?.name
                                : 'Ընտրացանկից ընտրեք թե ո՞ր բժշկական հաստատությունում եք ցանկանում իրականացնել գնահատումը'
                            }
                            style={{ width: '340px' }}
                            onChange={handleSelectHospital}
                          >
                            {healthsList.map((evListItemMap) => (
                              <Option key={evListItemMap.id} value={evListItemMap.id}>
                                {evListItemMap.name}
                              </Option>
                            ))}
                          </EdbSelect>
                        </Form.Item>
                      </Col>
                    </div>
                  )
              )}

              <p>
                <InfoCircleOutlined /> Գտնվելու վայրում գնահատումը կարող է իրականացվել միայն այն դեպքում, եթե
                դիմումատուն գտնվում է այնպիսի ծանր վիճակում, որ ի վիճակի չէ այցելել ՄՍԾ կենտրոն
              </p>
            </div>
          ) : (
            ''
          )
        )}
      </EdbCard>
      <div key={1}>
        <EdbCard
          title="Կցել փաստաթուղթ"
          style={{
            marginBottom: 10,
            borderTop: 0,
            borderBottom: '1px solid #C0C1C2',
            borderRight: 0,
            borderLeft: 0,
            padding: 0,
          }}
          className="form__person_document"
        >
          <AddDeleteTableRows subtypeId={subtypeId} />

          <Form.Item
            name="file_item_true"
            rules={[
              {
                required: true,
                message: 'Անհրաժեշտ է կցել բժշկական փաստաթղթերը՝ ընտրելով "Բժշկական փաստաթուղթ" տեսակը',
              },
            ]}
          >
            <Input type="hidden" value={true} />
          </Form.Item>
        </EdbCard>
        <p style={{ marginTop: '15px', color: '#555965' }}>
          <ExclamationCircleOutlined /> Բժշկական փաստաթուղթ է հանդիսանում բժշկական հաստատության կողմից տրված ուղեգիրը,
          հիվանդության էպիկրիզը, պատմությունը, անալիզների արդյունքները, եւ այլն
        </p>
        <p style={{ marginTop: '15px', color: '#555965' }}>
          <ExclamationCircleOutlined /> Բժշկական փաստաթղթերի կցումը պարտադիր է
        </p>
        <div style={{ width: '50%' }}>
          <p style={{ marginTop: '15px', color: '#555965' }}>
            <ExclamationCircleOutlined /> Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
          </p>
        </div>
      </div>

      <Row
        style={{
          gap: 10,
          borderBottom: '1px solid #C0C1C2',
        }}
      >
        <Col span={11} sm={24} xs={24} md={12} lg={11}>
          <EdbCard
            title={arrayListValTitles[1]}
            id="radio-group-title"
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
            tabIndex={0}
          >
            <Form.Item {...formItemLayout} name="radio_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group
                className="radlo_button"
                onChange={(e) => changeRadioNotice(e)}
                aria-labelledby="radio-group-title"
              >
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.name}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
        <Col span={11} sm={24} xs={24} md={12} lg={11}>
          <EdbCard
            title={arrayListValTitles[29]}
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
            tabIndex={0}
          >
            <Form.Item {...formItemLayout} name="receive_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group className="radlo_button" onChange={(e) => changeReceiveNotice(e)}>
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.name}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
      </Row>
    </>
  );
};

export default GeneralInfo;
