import styled from 'styled-components';
import { Card } from 'antd';

const EdbCard = styled(Card)`
  border: 1px solid #dddfe1;
  border-radius: 10px;
  margin-bottom: 10px;

  .ant-card-head-title {
    white-space: normal;
  }
  .ant-card-head {
    background: #0b847f;
    border-radius: 10px 10px 0px 0px;
    min-height: ${(props) => (props.title?.length ? '31px' : '20px')};
    color: #ffffff;
  }
`;

export default EdbCard;
