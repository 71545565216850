import React from "react";
import { Outlet } from 'react-router-dom';
import MainLayout from "../components/Layout/MainLayout";

export const PublicRoutes = () => {
  return (
    <MainLayout>
        <Outlet />
    </MainLayout>
  );
};
