import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router-dom';

import Error404 from './pages/error-pages/Error404';
import { PATHS } from './helpers/constants';
import { PublicRoutes } from './utils/PublicRoutes';
import HomePage from './pages/HomePage';
import GridStep from './components/GridStep';
import ApplicationInfoPrint from './components/GridStep/print';
import { Verify } from './pages/Verify';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PublicRoutes />}>
        <Route path={PATHS.ROOT} element={<Navigate to={PATHS.HOMEPAGE} replace />} />
        <Route path={PATHS.HOMEPAGE} element={<HomePage />} />
        <Route path={PATHS.USERAPPLICATION} element={<GridStep />} />
        <Route path={PATHS.VERIFY} element={<Verify />} />
        <Route path={`${PATHS.APPLICATIONINFO_PRINT}/:appInfoCode`} element={<ApplicationInfoPrint />} />
      </Route>

      <Route path={PATHS.ERROR_403} element={<Error404 />} />
      <Route path="*" element={<Navigate to={PATHS.ERROR_403} replace />} />
    </>
  )
);
