export const PATHS = {
  ROOT: '/',
  ERROR_403: 'no-access',
  SIGNIN: 'sign-in',
  HOMEPAGE: 'home',
  VERIFY: 'verify',
  CHANGEPASSWORD: 'change-password',
  FORGOTPASSWORD: 'forgot-password',
  RECOVERPASSWORD: 'recover-password',
  USERAPPLICATION: 'user-application',
  DASHBOARD: 'dashboard',
  APPLICATION: 'application',
  APPLICATION_DOCS: 'application/docs',
  APPLICATION_DATA: 'application/data',
  TEST: 'test',
  VIGNETTE: 'vignette',
  PROFILE: 'profile',
  NOTIFICATIONS: 'notifications',

  APPROVED_APPLICATION_LIST: 'approved-application/list',
  APPROVED_APPLICATION: 'approved-application',
  APPROVED_APPLICATION_DOCS: 'approved-application/docs',
  APPROVED_APPLICATION_DATA: 'approved-application/data',
  APPROVED_APPLICATION_VIGNETTE: 'approved-application/vignette',

  APPLICATIONPROTOCOLS: 'application-protocols',
  APPLICATIONPROTOCOL: 'application-protocol', //no usage
  APPLICATIONPROTOCOLS_VIGNETTE: 'application-protocols/vignette',
  APPLICATIONPROTOCOLS_APPLICATION: 'application-protocols/application',
  APPLICATIONPROTOCOLS_DOCS: 'application-protocols/docs',
  APPLICATIONPROTOCOLS_DATA: 'application-protocols/data',
  APPLICATIONDECISIONS: 'application-decisions',
  APPLICATIONDECISIONS_ADD: 'application-decisions/add',
  APPLICATIONDECISIONS_PRINT: 'application-decisions/print',
  APPLICATIONINFO_PRINT: 'application-info/print',
  APPLICATIONDECISIONS_VIGNETTE: 'application-decisions/vignette',
  APPLICATIONDECISIONS_APPLICATION: 'application-decisions/application',
  APPLICATIONDECISIONS_DOCS: 'application-decisions/docs',
  APPLICATIONDECISIONS_DATA: 'application-decisions/data',
  APPLICATIONDECISIONS_PROTOCOL: 'application-decisions/protocol',
  APPLICATIONDECISIONS_VIEW: 'application-decisions/view',

  SUSPENSIONS: 'suspensions',
  SUSPENSIONS_DOCS: 'suspensions/docs',
  SUSPENSIONS_DATA: 'suspensions/data',

  TERMINATIONS: 'terminations',
  TERMINATIONS_DOCS: 'terminations/docs',
  TERMINATIONS_DATA: 'terminations/data',

  APPLICATIONS: 'applications',
  OPERATOR_APPLICATION_DATA: 'op-application/data',
  OPERATOR_APPLICATION_DOCS: 'op-application/docs',
  OPERATOR_VIGNETTE: 'op-application/vignette',

  APPEALPROTOCOLS: 'appeal-protocols',
  APPEAL_PROTOCOL: 'appeal-protocol',
  APPEAL_PROTOCOL_APPLICATION: 'appeal-protocol/application',

  APPEAL_APPLICATION: 'appeal-applications',
  APPLICATION_APPEAL_VIGNETTE: 'application-appeal/vignette',
  APPLICATION_APPEAL_APPLICATION: 'application-appeal/application',
  APPLICATION_APPEAL_DOCS: 'application-appeal/docs',
  APPLICATION_APPEAL_DATA: 'application-appeal/data',
  APPLICATION_APPEAL_PROTOCOL: 'application-appeal/protocol',
  APPLICATION_APPEAL_VIEW: 'application-appeal/view',
  APPLICATION_APPEAL_PRINT: 'application-appeal/print',

  APPEAL_SUSPENSIONS: 'appeal-suspensions',
  APPEAL_HEAD_SUSPENSIONS_APPLICATION: 'appeal-suspensions-applications',

  APPEAL_TERMINATIONS: 'appeal-terminations',
  APPEAL_TERMINATIONS_APPLICATION: 'appeal-terminations/application',

  ALLAPPEALDECISIONS: 'appeal-decisions',
  ALLAPPEAL_DECISIONS_APPLICATION: 'appeal-decisions-application',
  ALLAPPEAL_DECISIONS_PROTOCOL: 'appeal-decisions-protocol',
  ALLAPPEAL_DECISIONS_DECISION: 'appeal-decisions-decision',
  ALLAPPEALDECISIONS_DOCS: 'appeal-decisions/docs',
  ALLAPPEALDECISIONS_DATA: 'appeal-decisions/data',
  ALLAPPEALDECISIONS_PROTOCOL: 'appeal-decisions/protocol',
  ALLAPPEALDECISIONS_VIEW: 'appeal-decisions/view',
  ALLAPPEALDECISIONS_PRINT: 'appeal-decisions/print',
  ALLAPPEALDECISIONS_VIGNETTE: 'appeal-decisions/vignette',

  OPERATOR_APPEAL_SUSPENSIONS: 'operator-appeal-suspensions',
  OPERATOR_SUSPENSIONS_APPLICATION: 'operator-appeal-suspensions-application',
  OPERATOR_SUSPENSIONS_DECISION: 'operator-appeal-suspensions-decision',
  OPERATOR_SUSPENSIONS_DECISION_DOCS: 'operator-appeal-suspensions-decision/docs',
  OPERATOR_SUSPENSIONS_DECISION_DATA: 'operator-appeal-suspensions-decision/data',
  OPERATOR_SUSPENSIONS_DECISION_PROTOCOL: 'operator-appeal-suspensions-decision/protocol',
  OPERATOR_SUSPENSIONS_DECISION_VIEW: 'operator-appeal-suspensions-decision/view',
  OPERATOR_SUSPENSIONS_DECISION_PRINT: 'operator-appeal-suspensions-decision/print',
  OPERATOR_SUSPENSIONS_DECISION_VIGNETTE: 'operator-appeal-suspensions-decision/vignette',

  OPERATOR_DECISIONS: 'operator-decisions',
  OPERATOR_DECISION_APP: 'operator-decision/application',
  OPERATOR_DECISION_DOCS: 'operator-decision/docs',
  OPERATOR_DECISION_DATA: 'operator-decision/data',
  OPERATOR_DECISION_PROTOCOL: 'operator-decision/protocol',
  OPERATOR_DECISION_VIEW: 'operator-decision/view',
  OPERATOR_DECISION_PRINT: 'operator-decision/print',
  OPERATOR_DECISION_VIGNETTE: 'operator-decision/vignette',

  DOCTOR_DECISIONS: 'doctor-decisions',
  DOCTOR_DECISION_APP: 'doctor-decision/application',
  DOCTOR_DECISION_DOCS: 'doctor-decision/docs',
  DOCTOR_DECISION_DATA: 'doctor-decision/data',
  DOCTOR_DECISION_PROTOCOL: 'doctor-decision/protocol',
  DOCTOR_DECISION_VIEW: 'doctor-decision/view',
  DOCTOR_DECISION_PRINT: 'doctor-decision/print',
  DOCTOR_DECISION_VIGNETTE: 'doctor-decision/vignette',
};

export const APP_SUBTYPES = {
  SUBTYPE_ONE: 1, // Առաջնակի գնահատում
  SUBTYPE_TWO: 2, // Առաջնակի գնահատում
  SUBTYPE_THREE: 3, // Առաջնակի գնահատում
  SUBTYPE_FOUR: 4, // Կրկնակի գնահատում
  SUBTYPE_FIVE: 5, // Կրկնակի գնահատում
  SUBTYPE_SIX: 6, // Կրկնակի գնահատում
  SUBTYPE_SEVEN: 7, // Վերագնահատում
  SUBTYPE_EIGHT: 8, // Վերագնահատում
  SUBTYPE_NINE: 9, // Վերագնահատում
  SUBTYPE_TEN: 10, // Մահվան պատճառական կապի որոշում
  SUBTYPE_ELEVEN: 11, // Կրկնակի գնահատում
};

export const HEADERS = {
  X_API_VERSION: '1.0',
};

/** Should match the route, to be able make it selected */
export const APPLICATION_TAB_KEYS = {
  APP: PATHS.APPLICATION,
  DATA: 'data',
  DOC: 'doc',
  VIGNETTE: PATHS.VIGNETTE,
};

export const VALIDATE_MESSAGES = {
  required: 'Պարտադիր դաշտ',
  types: {
    email: '${label} դաշտը վավեր չէ',
    number: '${label} դաշտը վավեր չէ',
  },
  number: {
    range: '${label}ը պետք է լինի ${min} և ${max}',
  },
};

export const GENDER_LIST = {
  M: 'Արական',
  W: 'Իգական',
  F: 'Իգական',
};

export const EVALUATION_MEDIC = {
  MEDIC: 3,
  HARMEDIC: 3,
  SUM_EVALUATION: 5,
};

export const DICTIONARY_URL = {
  GET_CHRONICLE_PROTOCOL_TYPES: 'api/dictionary/getChronicalProtocolTypes',
  GET_FMD_CODES: 'api/dictionary/getICFCodes',
  GET_PROTOCOL_TYPES: 'api/dictionary/getProtocolTypes',
  GET_USER_PARAMEDIC_TYPES: 'api/User/getParamedicTypes',
  GET_DISABILITY_CAUSES: 'api/dictionary/GetDisabilityCauses',
  GET_DISABILITY_PERIODS: 'api/dictionary/GetDisabilityPeriods',
  GET_DISABILITY_SCORE_DESCRIPTIONS: 'api/dictionary/GetDisabilityScoreDescriptions',
  GET_DISABILITY_EVALUATION_TYPES: 'api/dictionary/GetEvaluationTypes',
  GET_APPLICATION_STATUSES: 'api​/dictionary​/getApplicationStatuses',
  GET_REGIONS: 'EDisability/GetAllRegions',
  GET_COMMUNITIES_BY_REGION_ID: 'EDisability/GetCommunitiesByRegionId',
  GET_SETTLEMENT_BY_COMMUNITY_ID: 'EDisability/GetSettlementByCommunityId',
  GET_ALL_OFFICES: 'EDisability/GetOfficesByCommunityId',
  GET_ALL_HEALTHS: 'EDisability/GetAllHealthFacilities',
  GET_POST_OFFICES_BY_REGION_ID: 'EDisability/GetPostOfficesByRegionId',
  GET_CAUSES: 'EDisability/GetDisabilityCause',
  GET_NOTIFICATION_TYPES: 'EDisability/GetAllNotificationTypes',
  GET_FILE_BY_NAME: '/EDisability/GetFileByName',
  GET_ALL_EDUCATIONS: 'EDisability/GetAllEducations',
  GET_ALL_OCCUPATIONS: 'EDisability/GetAllOccupations',
  GET_SUBTYPES: 'EDisability/GetEvaluationWithSubtypes',
  GET_ATTACHED_DOCS: 'EDisability/GetAttachedDocumentTypes',
};

export const TELEPHONE_MASK = '+37400000000';

export const OPERATOR_MENU = {
  APPLICATIONS: {
    label: 'Դիմումներ',
    url: `/${PATHS.APPLICATIONS}`,
  },
  APPLICATIONDECISIONS: {
    label: 'Որոշումներ',
    url: `/${PATHS.OPERATOR_DECISIONS}`,
  },
  SUSPENSIONS: {
    label: 'Կասեցված գործեր',
    url: `/${PATHS.SUSPENSIONS}`,
  },
  TERMINATIONS: {
    label: 'Կարճված գործեր',
    url: `/${PATHS.TERMINATIONS}`,
  },
  APPEAL_SUSPENSIONS: {
    label: (
      <>
        Բողոքարկման
        <br />
        կասեցված գործեր
      </>
    ),
    url: `/${PATHS.OPERATOR_APPEAL_SUSPENSIONS}`,
  },
};
export const SPECIALIST_MENU = {
  APPROVED_APPLICATION_LIST: {
    label: (
      <>
        Հաստատված <br /> ամփոփագրեր
      </>
    ),
    url: `/${PATHS.APPROVED_APPLICATION_LIST}`,
  },
};

export const COMPLAINT_EXAMINER_MENU = {
  APPEALPROTOCOLS: {
    label: 'Արձանագրություններ',
    url: `/${PATHS.APPEALPROTOCOLS}`,
  },
  ALLAPPEALDECISIONS: {
    label: 'Որոշումներ',
    url: `/${PATHS.ALLAPPEALDECISIONS}`,
  },
};

export const APPEAL_HEAD_MENU = {
  APPEALAPPLICATION: {
    label: 'Բողոքարկման դիմում',
    url: `/${PATHS.APPEAL_APPLICATION}`,
  },
  SUSPENSIONS: {
    label: 'Կասեցված դիմումներ',
    url: `/${PATHS.APPEAL_SUSPENSIONS}`,
  },
  TERMINATIONS: {
    label: 'Կարճված դիմումներ',
    url: `/${PATHS.APPEAL_TERMINATIONS}`,
  },
  ALLAPPEALDECISIONS: {
    label: 'Որոշումներ',
    url: `/${PATHS.ALLAPPEALDECISIONS}`,
  },
  APPEALPROTOCOLS: {
    label: 'Արձանագրություն',
    url: `/${PATHS.APPEALPROTOCOLS}`,
  },
};

export const PROTOCOLS = {
  MOBILITY: 1,
  HSV: 2,
  CHRONICLE: 3,
  VISUAL: 4,
  MENTAL: 5,
};
export const PAGE_SIZE = 10;
export const PAGE_NUMBER = 1;
export const DEFAULT_PAGE = { pageNumber: PAGE_NUMBER, pageSize: PAGE_SIZE };

export const VIGNETTE_STATUS = {
  NO_READ: 0,
  READ: 1,
};

export const DOWNLOAD_FILE = {
  STATIC_FILE: '/api/staticFile',
};

export const EVALUATION_TYPE = {
  VIEW: 'view',
  EDIT: 'edit',
};

export const AGE_GROUP_UNDER_18 = 0;
export const AGE_GROUP_OVER_18 = 1;

export const AGE_GROUP_LIST = [
  {
    id: AGE_GROUP_UNDER_18,
    name: 'Մինչև 18 տարեկան անձանց համար',
  },
  {
    id: AGE_GROUP_OVER_18,
    name: '18 տարեկանից բարձր անձանց համար',
  },
];

export const NOTIFICATION_LIMIT = 5;

export const NOTIFICATION_STATUS = {
  0: 'Նոր',
  1: 'Ընթերցված',
  2: 'Ստեղծված',
  3: 'Սպասվող',
  4: 'Ընդունված',
  5: 'Մերժված',
  6: 'Ժամկետանց',
  7: 'Հաստատված',
};

export const TEXT_NOT_FOUND = 'Տվյալներ չեն գտնվել';

export const NO = 0;
export const YES = 1;
export const EVALUATION_LOCATION_TYPES_MSC = 2;

export const DOCTOR_TYPE = {
  DOCTOR: 0,
  HAR_DOCTOR: 1,
};

export const DOCTOR_TYPE_NAME = {
  [DOCTOR_TYPE['DOCTOR']]: 'Բժիշկ',
  [DOCTOR_TYPE['HAR_DOCTOR']]: 'Հարբժիշկ',
};

export const columnsTableInfo = [
  {
    title: 'Որոշման համար',
    dataIndex: 'decisionNumber',
  },
  {
    title: 'Ֆունկցիոնալության գնահատման ամսաթիվ',
    dataIndex: 'functionalityEvaluationDate',
  },
  {
    title: 'Ֆունկցիոնալության գնահատման տեսակ',
    dataIndex: 'typeOfFunctionalEvaluation',
  },
  {
    title: 'Իրավունակ է բողոքարկման համար',
    dataIndex: 'eligibleForAppeal',
  },
];
