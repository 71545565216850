import { useMutation } from '@tanstack/react-query';
import client from "../clientsubmitapplication";

const url = '/EDisability/GetQkagDeathInfo';

const usePersonDeath = (options = {}) => {
    const mutation = useMutation((values) => {
        return client.post(url, values);
    }, options);
    return mutation;
};


export default usePersonDeath;
