import { Col, Form, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { evaluationList, formItemLayout, requiredMessage } from '../../../helpers/userapp';
import EdbSelect from '../../Select';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import { DICTIONARY_URL } from '../../../helpers/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import EdbCard from '../../Card';

const { Option } = Select;

export const ValuationPlaceSelect = () => {
  const form = Form.useFormInstance();
  const locationId = Form.useWatch('assessment_location_id');
  const inPlaceLocationId = Form.useWatch('inpalce_location_id');
  const regionId = Form.useWatch('communityHiddenName');
  const region = Form.useWatch('communityName');

  const hospitalId = Form.useWatch('hospital_id');
  const ussCenterId = Form.useWatch('uss_center_id');

  const [offices, setOffices] = useState([]);
  const { data: hospitals } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_HEALTHS);
  const hospitalName = hospitals.filter((item) => item.id === hospitalId);
  const ussCenterName = offices.filter((item) => item.id === ussCenterId);
  const locationName = evaluationList.filter((item) => item.id === locationId);

  useGetDictionaryPublic(
    DICTIONARY_URL.GET_ALL_OFFICES,
    {
      enabled: !!(regionId && locationId === 2),
      onSuccess: (data) => {
        setOffices(data);
      },
    },
    { communityId: regionId }
  );

  useEffect(() => {
    let medicalInstitutions = null;
    let ussCenter = null;
    if (hospitalId) {
      medicalInstitutions = {
        medicalInstitutionId: hospitalId || null,
        medicalInstitutionName: hospitalName[0]?.name || null,
      };
    }
    if (form.getFieldValue('uss_center_id')) {
      ussCenter = {
        ussCenterId: form.getFieldValue('uss_center_id') || null,
        ussCenterName: ussCenterName[0]?.name || null,
      };
    }
    form.setFieldValue('assessment_data', {
      locationValue: locationId || null,
      locationName: locationName[0]?.label || null,
      stayingLocation: {
        stayingLocationId: regionId,
        stayingLocationName: region,
        medicalInstitutions,
      },
      ussCenter,
    });
  }, [form, hospitalId, hospitalName, locationId, locationName, region, regionId, ussCenterName]);

  return (
    <EdbCard title="Խնդրում եմ գնահատումն իրականացնել" className="form__person_place" style={{ border: 0 }}>
      <Col span={7} xs={24} sm={11} md={7}>
        <Form.Item
          {...formItemLayout}
          name="assessment_location_id"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <EdbSelect placeholder="Ընտրել" aria-label="Ընտրացանկից ընտրեք թե որտե՞ղ եք ցանկանում իրականացնել գնահատումը">
            {evaluationList.map((item) => {
              return (
                <Option key={item.id} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </EdbSelect>
        </Form.Item>
        {locationId === 1 && (
          <Form.Item name="inpalce_location_id" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect placeholder="Ընտրել">
              {evaluationList[1].evalution_select.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                );
              })}
            </EdbSelect>
          </Form.Item>
        )}
        {locationId === 2 && (
          <Form.Item name="uss_center_id" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect placeholder="Ընտրել">
              {offices.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </EdbSelect>
          </Form.Item>
        )}
        {inPlaceLocationId === 1 && (
          <Form.Item name="hospital_id" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect placeholder="Ընտրել">
              {hospitals.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </EdbSelect>
          </Form.Item>
        )}
      </Col>
      {locationId === 1 && (
        <p>
          <InfoCircleOutlined /> Գտնվելու վայրում գնահատումը կարող է իրականացվել միայն այն դեպքում, եթե դիմումատուն
          գտնվում է այնպիսի ծանր վիճակում, որ ի վիճակի չէ այցելել ՄՍԾ կենտրոն
        </p>
      )}
      <Form.Item name={'assessment_data'} />
    </EdbCard>
  );
};
