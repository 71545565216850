import React from 'react';
import { ReactComponent as CoatOfArms } from './images/coat-of-arms.svg';
import styled from 'styled-components';
import { Col, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../helpers/constants';

const { Text } = Typography;

const LogoName = styled(Text)`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px !important;
  color: #091024;
`;

const Logo = () => {
  const navigate = useNavigate();
  return (
    <button style={{ background: 'none', border: 'none', cursor: "pointer" }} onClick={() => navigate(PATHS.HOMEPAGE)}>
      <Space size="middle">
        <CoatOfArms aria-label="Հայաստանի Հանրապետության Զինանշան" style={{ width: '80px', height: '80px' }} />
        <LogoName>
          <Row>
            <Col>Աշխատանքի</Col>
          </Row>
          <Row>
            <Col>եվ սոցիալական հարցերի</Col>
          </Row>
          <Row>
            <Col>նախարարություն</Col>
          </Row>
        </LogoName>
      </Space>
    </button>
  );
};

export default Logo;
