import { DatePicker } from 'antd';
import 'moment/locale/hy-am';
import styled from 'styled-components';

import './index.css';

const DatePickerInput = styled(DatePicker)`
  height: 45px;
  background: #ffffff;
  border: 1px solid #dddfe1;
  border-radius: 10px;
  border: 1px solid #dddfe1;
  display: flex;

  :disabled {
    background-color: #ffffff;
  }
  :active {
    border: 1px solid #3c9d99;
  }
  :hover {
    border: 1px solid #3c9d99;
  }
  :focus {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 0.1875rem #bd13b8;
    outline: none;
  }
`;

const EdbDatePicker = (props) => <DatePickerInput format="DD/MM/YYYY" popupClassName="edb-datepicker" {...props} />;

export default EdbDatePicker;
