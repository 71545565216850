import {NUMBERS_USER} from "../../../helpers/userapp";
import OptionTitle from "../OptionTitle";

export default function inforeducer(state, action) {
    const arrayListValTitles = OptionTitle(true);
    switch (action.id) {
        case NUMBERS_USER.ITEMCASEONE: {
            return {
                ...state,
                getProffessionType: arrayListValTitles[31],
                getProffessionTypeRequired: true,
                getProffessionTypeVisible: true,
            }
        }
        case NUMBERS_USER.ITEMCASETWO: {
            return {
                ...state,
                getProffessionType: arrayListValTitles[4],
                getProffessionTypeRequired: false,
                getProffessionTypeVisible: true,
            };
        }
        case NUMBERS_USER.ITEMCASETHREE: {
            return {
                ...state,
                getProffessionType: '',
                getProffessionTypeRequired: false,
                getProffessionTypeVisible: false,
            };
        }
        case NUMBERS_USER.ITEMCASEFOURE: {
            return {
                ...state,
                getProffessionType: arrayListValTitles[4],
                getProffessionTypeRequired: true,
                getProffessionTypeVisible: true,
            };
        }
        case NUMBERS_USER.ITEMCASEFIVE: {
            return {
                ...state,
                getProffessionType: '',
                getProffessionTypeRequired: false,
                getProffessionTypeVisible: false,
            };
        }
        default: {
            return {
                ...state,
                getProffessionType: '',
                getProffessionTypeRequired: true,
                getProffessionTypeVisible: false,
            };
        }
    }
}