import styled from 'styled-components';
import { Button } from 'antd';

const EdbButton = styled(Button)`
  border-radius: 30px;
  border: 2px solid #0b847f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 40px 13px 40px;
  gap: 10px;
  height: 48px;
  ${'' /* width: 100%; */}

  &.ant-btn {
    padding: 13px 40px 13px 40px !important;
  }

  &.ant-btn-default {
    color: #0b847f;

    &:hover {
      background-color: #0b847f;
      color: #ffffff;
      border: 2px solid #0b847f;
    }
  }

  &.ant-btn-primary {
    background-color: #0b847f;
    color: #ffffff;
    border: 2px solid #0b847f;
  }

  &:disabled {
    background-color: #cee6e5;
    color: #ffffff;
  }

  &:focus {
    border-color: #0b847f;
  }
`;

export default EdbButton;
