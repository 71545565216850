import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const DefaultContent = ({ children }) => (
  <main id='main'>
    {children}
  </main>
);

export default DefaultContent;
