import { useMutation } from '@tanstack/react-query';

import clientsubmit from '../clientsubmitapplication';

const url = 'EDisability/CheckMilitaryDecision';

const useVerifyMilitaryData = (restOptions) => {
  const mutation = useMutation((values) => {
    return clientsubmit.post(url, values);
  }, restOptions);
  return mutation;
};

export default useVerifyMilitaryData;
