import { Form } from "antd";
import styled from "styled-components";

const EdbForm = styled(Form)`
    &.ant-form{
    .ant-form-item-required {
        &:before {
            content: "" !important;
        }

        &:after {
            display: inline-block;
            margin-left: 4px;
            color: #CC0000;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: "*";
        }
    }
}
`;

export default EdbForm;